import { FunctionComponent } from "react";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';

const BookingSlider: FunctionComponent<{ id: string }> = (id,) => {
  return (
    <section className="self-stretch flex flex-row items-start justify-center pt-6 lg:px-[1.25rem] pb-[4.625rem] box-border max-w-full text-left text-[1.231rem] font-chivo mq450:pb-[3rem] mq450:box-border">
      <div className="flex flex-row items-start justify-start relative max-w-full">
        {/* <img src="rectangle-81@2x.webp" style={{ width: '100%', objectFit: 'cover', height: '400px', borderRadius: '18px' }} alt="Image 1" /> */}
        <Splide
          options={{
            autoplay: true,
            arrows: false,
            type: 'loop',
            interval: 4000,
            gap: 20,
          }} aria-label="My Favorite Images">
          <SplideSlide>
            <img src="serendib/1.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 1" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/2.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/3.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/4.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/5.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/6.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/7.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/8.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/9.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/10.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/11.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/12.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/13.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/14.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/15.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/16.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
          <SplideSlide>
            <img src="serendib/17.webp" style={{ width: '100%', height: '400px', objectFit: 'cover', borderRadius: '16px' }} alt="Image 2" />
          </SplideSlide>
        </Splide>
      </div>
    </section>
  );
};

export default BookingSlider;