import Navbar from "../components/Navbar"
import FooterHome from "../components/FooterHome"
import { useNavigate } from "react-router-dom";
import Button from "../components/Button";

export default function PageNotFound() {
    const navigate = useNavigate();
    return (
        <div className="flex flex-col justify-between bg-dark-blue min-h-dvh">
            <div className="w-full relative overflow-hidden flex flex-row items-start justify-center pt-[0rem] pb-[0.012rem] px-[7.813rem] gap-[9.375rem] text-left text-[0.875rem] text-pure-white">
                <div className="w-fit h-full flex flex-col items-start justify-center pt-[3.381rem] pb-[128px] min-w-[28.188rem] max-w-full">
                    <Navbar />
                    <div className="flex flex-col items-center justify-center w-full gap-8">
                        <img width={250} src="/404.svg" alt="" />
                        <h1 className="m-0 title-gradient">
                            404
                        </h1>
                        <h2 className="title-gradient m-0 self">Oops! You drew a red card.</h2>
                        <div>
                            <Button btnText="Go back home" classes="!mt-0 px-4" click={() => { navigate('/') }} />
                        </div>
                    </div>
                </div>
            </div>
            <FooterHome />
        </div>
    )
}