import { useNavigate } from "react-router-dom";
import FooterHome from "../components/FooterHome";
import Navbar from "../components/Navbar";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import UserRequest from "../services/userRequest";
import { useEffect, useState } from "react";
import Button from "../components/Button";
import ReviewModal from "../components/ReviewModal";
import StarIcon from '@mui/icons-material/Star';
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";

interface bookingProps {
    id: number,
    date: string,
    location: string,
    activity: string,
    rating: number,
    startTime: string,
    endTime: string,
    duration: string,
    totalPrice: string,
    statusName: string,
}

export default function Bookings() {
    const navigate = useNavigate();
    const [rows, setRows] = useState<bookingProps[]>([])
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [status, setStatus] = useState('0');

    // Handler to open modal
    const handleOpenModal = () => setIsModalOpen(true);

    // Handler to close modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
        getBookings();
    }

    const getBookings = async (status: string = '0') => {
        try {
            const response = await UserRequest.get(`/Booking/customer/all?status=${status}`);
            const data = response.data.data;
            const processedData = data.map((booking: bookingProps) => ({
                ...booking,
                date: formatDateFromISO(booking.date),
                startTime: formatTimeFromISO(booking.startTime),
                endTime: formatTimeFromISO(booking.endTime),
            }));
            setRows(processedData);
        } catch (error) {
            console.log(error);
        }
    }

    const handleChange = (event: SelectChangeEvent) => {
        setStatus(event.target.value as string);
        getBookings(event.target.value as string);
    };

    // Function to format date from ISO string
    function formatDateFromISO(isoString: string): string {
        return new Date(isoString).toLocaleDateString();
    }

    // Function to format time from ISO string
    function formatTimeFromISO(isoString: string): string {
        let date = isoString.split("T")[1];
        return date.substring(0, 5);
    }

    useEffect(() => {
        getBookings(status);
    }, [])

    return (
        <>
            <div className="bg-dark-blue flex flex-col min-h-dvh pt-14">
                <Navbar />
                <div className="bookings w-[71rem] mx-auto flex flex-1">
                    <div className="flex flex-col flex-1 items-start justify-center self-stretch w-full h-full gap-[5.669rem] ">
                        <div className="w-full flex flex-col items-start justify-start gap-[1.688rem]">
                            <div className="w-full flex flex-row items-end justify-between mt-14">
                                <h3 className="m-0 title-gradient">
                                    Your Bookings
                                </h3>
                                <FormControl sx={{ m: 1, minWidth: '180px' }}>
                                    <Select
                                        labelId="status"
                                        id="status"
                                        value={status}
                                        onChange={handleChange}
                                        sx={
                                            {
                                                ".MuiSelect-select": {
                                                    border: "1px solid #fff",
                                                    color: "#fff",
                                                    borderRadius: "16px"
                                                },
                                                ".MuiSelect-icon": {
                                                    fill: "#fff",
                                                    border: "1px solid #fff",
                                                    borderRadius: "16px",
                                                    marginRight: "4px"
                                                }
                                            }
                                        }
                                    >
                                        <MenuItem value={'0'}>All</MenuItem>
                                        <MenuItem value={'2'}>Paid</MenuItem>
                                        <MenuItem value={`6`}>Complete</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <TableContainer>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow className="text-transparent !bg-clip-text [background:linear-gradient(89.74deg,_#baffd4,_#82f8ff_40.33%,_#ffd3d8)]">
                                            <TableCell className="text-transparent text-xl">#</TableCell>
                                            <TableCell className="text-transparent text-xl">Date</TableCell>
                                            <TableCell className="text-transparent text-xl">Location</TableCell>
                                            <TableCell className="text-transparent text-xl">Court Type</TableCell>
                                            <TableCell className="text-transparent text-xl">Duration</TableCell>
                                            <TableCell className="text-transparent text-xl">Payment</TableCell>
                                            <TableCell className="text-transparent text-xl">Status</TableCell>
                                            <TableCell className="text-transparent text-xl">Review</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {rows.map((row) => (
                                            <>
                                                <TableRow
                                                    key={row.id}
                                                    sx={{
                                                        '&:last-child td, &:last-child th': { border: 0 },
                                                        color: '#fff'
                                                    }}
                                                >
                                                    <TableCell sx={{ color: '#fff' }} className="text-xl" component="th" scope="row">
                                                        {row.id}
                                                    </TableCell>
                                                    <TableCell sx={{ color: '#fff' }} className="text-xl" component="th" scope="row">
                                                        {row.date}
                                                    </TableCell>
                                                    <TableCell sx={{ color: '#fff' }} className="text-xl">{row.location}</TableCell>
                                                    <TableCell sx={{ color: '#fff' }} className="text-xl">{row.activity}</TableCell>
                                                    <TableCell sx={{ color: '#fff' }} className="text-xl">{row.startTime} - {row.endTime} | {row.duration}</TableCell>
                                                    <TableCell sx={{ color: '#fff' }} className="text-xl">{row.totalPrice}</TableCell>
                                                    <TableCell sx={{ color: '#fff' }} className="text-xl">{row.statusName}</TableCell>
                                                    <TableCell sx={{ color: '#fff' }} className="text-xl">
                                                        {row.rating !== 0 ? (
                                                            <div className="flex items-center">
                                                                {[...Array(row.rating)].map((_, index) => (
                                                                    <StarIcon
                                                                        key={index}
                                                                        sx={{
                                                                            color: '#ff9cba',
                                                                            fontSize: 24  // Adjust size as needed
                                                                        }}
                                                                    />
                                                                ))}
                                                            </div>
                                                        ) : (
                                                            <Button
                                                                click={handleOpenModal}
                                                                btnText='Add a Review'
                                                                classes='!py-2 !px-4 !mt-0 !w-fit'
                                                            />
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                                <ReviewModal
                                                    bookingId={row.id}
                                                    open={isModalOpen}
                                                    onClose={handleCloseModal}
                                                />
                                            </>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </div>
                </div>
                <FooterHome />
            </div>
        </>
    );
}