import axios from "axios";
import { API_URL } from '../config';

const baseURL = `${API_URL}`;


const AuthRequest = axios.create({
  baseURL,
  timeout: 30000,
});

export default AuthRequest;
