import React, { FunctionComponent, useCallback, useEffect } from "react";
import {
  Select,
  MenuItem,
  FormHelperText,
  FormControl,
  SelectChangeEvent,
} from "@mui/material";

interface ActivityListProps {
  id: number;
  price: number;
  name: string;
  typeName: string;
}

export type ConnectorType = {
  selectSport?: string;
  courtId: string;
  sportNames: ActivityListProps[];
  onCourtChange: (court: string) => void;
  onPriceChange: (price: number) => void;
};

const Connector: FunctionComponent<ConnectorType> = ({
  selectSport,
  sportNames,
  onCourtChange,
  onPriceChange,
  courtId,
}) => {
  const updateSportInfo = useCallback((id: string) => {
    const selectedSport = sportNames.find(sport => sport.id === Number(id));
    if (selectedSport) {
      onPriceChange(selectedSport.price);
      return {
        price: selectedSport.price,
        typeName: selectedSport.typeName,
      };
    }
    return { price: 0, typeName: '' };
  }, [sportNames, onPriceChange]);

  useEffect(() => {
    onCourtChange(courtId);
    updateSportInfo(courtId);
  }, [courtId, onCourtChange, updateSportInfo]);

  const handleChange = (e: SelectChangeEvent<string>) => {
    const newCourtId = e.target.value;
    onCourtChange(newCourtId);
    updateSportInfo(newCourtId);
  };

  const { price, typeName } = updateSportInfo(courtId);

  return (
    <div className="w-full flex flex-col items-start justify-start gap-[1.063rem_0rem]">
      <div className="title-gradient label2 uppercase">
        {selectSport}
      </div>
      <div className="w-full flex lg:flex-col items-center justify-end gap-8">
        <FormControl
          className="h-[3rem] flex-1 lg:flex-auto flex justify-center"
          variant="standard"
          sx={{
            border: "1px solid #fff",
            borderRadius: "16px",
            "& .MuiInputBase-root": {
              justifyContent: "center",
              display: "inline-flex",
            },
            "& .MuiInputLabel-root": {
              minHeight: "48px",
              display: "inline-flex",
            },
            "& .MuiMenuItem-root": {
              height: "48px",
              display: "inline-flex",
            },
            "& .MuiSelect-select": {
              height: "48px",
              width: "100%",
              alignItems: "center",
              display: "flex",
            },
            "& .MuiInputBase-input": {
              color: "#fff",
              fontSize: 18,
              fontWeight: "Medium",
              fontFamily: "Inter",
              textAlign: "left",
              p: "0 !important",
              marginLeft: "16px",
            },
            '& .MuiSvgIcon-root': {
              color: '#fb709a',
              fontSize: 18,
              marginRight: '16px',
              borderRadius: '50%',
              border: '1px solid #fb709a',
            }
          }}
        >
          <Select
            color="primary"
            disableUnderline
            displayEmpty
            value={courtId}
            onChange={handleChange}
          // IconComponent={() => (
          //   <img
          //     width="24px"
          //     height="24px"
          //     src="/ggchevrondowno.svg"
          //     style={{ marginRight: "16px" }}
          //   />
          // )}
          >
            <MenuItem value='0' disabled>Select Court</MenuItem>
            {sportNames.map(sport => (
              <MenuItem key={sport.id} value={sport.id}>{sport.name}</MenuItem>
            ))}
          </Select>
          <FormHelperText />
        </FormControl>
        <div className="flex items-center gap-4">
          <div className="rounded-2xl flex flex-row justify-start bg-gravel1 h-12 items-center px-6">
            <div className="text-white">
              {price.toLocaleString()} /hr
            </div>
          </div>
          <div className="flex justify-center items-center">
            {typeName ? (
              <img
                className="flex items-center"
                src={`/${typeName.toLowerCase()}.png`}
                height={40}
              />
            ) : ''}
            {/* <p>{typeName}</p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connector;
