import { Link, useNavigate } from "react-router-dom";
import FooterHome from "../components/FooterHome";
import Navbar from "../components/Navbar";
import UserRequest from "../services/userRequest";
import { FormEvent, useEffect, useState } from "react";
import { Box, Modal, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Button from "../components/Button";

interface UserProps {
  id: number;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  email: string;
  contact: string;
}

type PasswordFields = "currentPassword" | "newPassword" | "confirmPassword";

export default function Account() {
  const navigate = useNavigate();

  // State for form inputs
  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    contact: "",
    email: "",
    password: "******",
  });

  const [passwords, setPasswords] = useState<{
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
  }>({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  });

  const [visibility, setVisibility] = useState<{
    currentPassword: boolean;
    newPassword: boolean;
    confirmPassword: boolean;
  }>({
    currentPassword: false,
    newPassword: false,
    confirmPassword: false,
  });

  const citiesInSriLanka: string[] = [
    "Agalawatta",
    "Agunukolapelassa",
    "Ahangama",
    "Ahungalla",
    "Akkareipathuwa",
    "Akmeemana",
    "Akurana",
    "Akuressa",
    "Alawwa",
    "Aluthgama",
    "Ambalangoda",
    "Ambanpola",
    "Ambepussa",
    "Ampara",
    "Anamaduwa",
    "Anguruwathota",
    "Anuradhapura",
    "Aralagamwila",
    "Aranayaka",
    "Athkadura",
    "Attanagalla",
    "Attidiya",
    "Aturugiriya",
    "Avissawella",
    "Ayagama",
    "Baddegama",
    "Badulla",
    "Baduraliya",
    "Balangoda",
    "Bandaragama",
    "Bandarawela",
    "Bangadeniya",
    "Batapola",
    "Battaluoya",
    "Battaramulla",
    "Batticaloa",
    "Beliatta",
    "Benthota",
    "Beruwala",
    "Bibila",
    "Bingiriya",
    "Bogawantalawa",
    "Boralesgamuwa",
    "Bulathkohupitiya",
    "Bulathsinghala",
    "Buthala",
    "Chillaw",
    "Colombo 01 - ( Fort )",
    "Colombo 02 - ( Union Place )",
    "Colombo 03 - ( Kollupitiya )",
    "Colombo 04 - ( Bambalapitiya )",
    "Colombo 05 - ( Havelock / Kirulapana )",
    "Colombo 06 - ( Wellawatta )",
    "Colombo 07 - ( Cinnamon Gardens )",
    "Colombo 08 - ( Borella )",
    "Colombo 09 - ( Dematagoda )",
    "Colombo 10 - ( Maradana )",
    "Colombo 11 - ( Pettah )",
    "Colombo 12 - ( Hultsdorf )",
    "Colombo 13 - ( Kotahena )",
    "Colombo 14 - ( Grandpass )",
    "Colombo 15 - ( Mattakuliya )",
    "Dambulla",
    "Dankotuwa",
    "Dehiathakandiya",
    "Dehiwala",
    "Deiyandara",
    "Delgoda",
    "Delkanda",
    "Deltota",
    "Deniyaya",
    "Deraniyagala",
    "Dharga Town",
    "Digana",
    "Diyabeduma",
    "Dodanduwa",
    "Dompe",
    "Dunagaha",
    "Dvulapitiya",
    "Eheliyagoda",
    "Ekala",
    "Elahera",
    "Elpitiya",
    "Embilipitiya",
    "Eppawala",
    "Galaha",
    "Galewela",
    "Galgamuwa",
    "Galhinna",
    "Galle",
    "Galnewa",
    "Gampaha",
    "Gampola",
    "Ganemulla",
    "Gangodawila",
    "Gatahetta",
    "Gelioya",
    "Ginigathhena",
    "Ginthota",
    "Giradurukotte",
    "Giriulla",
    "Godagama",
    "Godakawela",
    "Gonapeenuwala",
    "Gothatuwa",
    "Habarana",
    "Hakmana",
    "Hambanthota",
    "Hanguranketha",
    "Hanwella",
    "Hatton",
    "Henegama",
    "Hettipola Matale",
    "Hikkaduwa",
    "Hingurakgoda",
    "Hokandara",
    "Homagama",
    "Horana",
    "Horawala",
    "Horowpothana",
    "Ibbagamuwa",
    "Idangoda",
    "Imaduwa",
    "Ingiriya",
    "Ja-Ela",
    "Jaffna",
    "Kadawatha",
    "Kadugannawa",
    "Kaduwala",
    "kahawatta",
    "Kalawana",
    "Kalmunai",
    "Kalpitiya",
    "Kalubowila",
    "Kaluthara",
    "Kamburupitiya",
    "Kandana",
    "Kandy",
    "Kanthale",
    "Karagampitiya",
    "Karuwalagaswewa",
    "Katana",
    "Kataragama",
    "Katubedda",
    "Katugasthota",
    "Katunayaka",
    "Katuneriya",
    "Katupotha",
    "Kebithigollewa",
    "Kegalle",
    "Kekirawa",
    "Kelaniya",
    "Kesbewa",
    "Kilinochchiya",
    "Kirama",
    "Kiribathgoda",
    "Kiriella",
    "Kirillawala",
    "Kirindiwela",
    "Kochchikade",
    "Kohuwala",
    "Kolonna",
    "Kolonnawa",
    "Kosgama",
    "Koswatha",
    "Kotadeniyawa",
    "Kotikawatta",
    "Kotiyakumbura",
    "Kotmale",
    "Kottawa",
    "Kotte",
    "Kuliyapitiya",
    "Kumbukgate",
    "Kundasale",
    "Kurunegala",
    "Kuruwita",
    "Laggala Pallegama",
    "Lunugala",
    "Lunuwila",
    "Madagalla",
    "Madampe",
    "Madawala",
    "Madurankuliya",
    "Maha oya",
    "Mahabage",
    "Maharagama",
    "Mahawewa",
    "Mahiyanganaya",
    "Maho",
    "Makandura",
    "Makandura Matara",
    "Makola",
    "Malambe",
    "Mallawapitiya",
    "Malwana",
    "Mankulum",
    "Mannar",
    "Mapalagama",
    "Maradankadawala",
    "Marandagahamula",
    "Marawila",
    "Maskeliya",
    "Matale",
    "Matara",
    "Mathugama",
    "Mattegoda",
    "Mawanella",
    "Medamahanuwara",
    "Medawachiya",
    "Medirigiriya",
    "Meegoda",
    "Melsiripura",
    "Middeniya",
    "Minuwangoda",
    "Mirigama - ( N )",
    "Mirihana",
    "Mirissa",
    "Monaragala",
    "Moragala",
    "Moragollagama",
    "Moratuwa",
    "Morawaka",
    "Mount Lavinia",
    "Mullathiv",
    "Mulleriyawa",
    "Mundala",
    "Mutur",
    "Narahempita",
    "Narammala",
    "Nattandiya",
    "Naula",
    "Nawagathegama",
    "Nawala",
    "Nawalapitiya",
    "Neboda",
    "Negombo",
    "Nelumdeniya",
    "Neluwa",
    "Nikaweratiya",
    "Nittambuwa",
    "Nivithigala",
    "Nugegoda",
    "Nuwara Eliya",
    "Padaviya",
    "Padiyapelalla",
    "Padiyatalawa",
    "Padukka",
    "Palavee",
    "Pamunugama",
    "Panadura",
    "Panamure",
    "Panchikawatte",
    "Pannala",
    "Pannipitiya",
    "Panwila",
    "Pasgoda",
    "Passara",
    "Pasyala",
    "Payagala",
    "Pelawatta",
    "Pelawatta Mathugama",
    "Peliyagoda",
    "Pelmadulla",
    "Peradeniya",
    "Pilimathalawa",
    "Piliyandala",
    "Pitabeddara",
    "Pitigala",
    "Polgahawela",
    "Polgasovita",
    "Polgasowita",
    "Polhengoda",
    "Polonnaruwa",
    "Polpithigama",
    "Pothuvil",
    "Pugoda",
    "Pussallawa",
    "Puttalam",
    "Radavana",
    "Raddolugama",
    "Ragama",
    "Rajagiriya",
    "Rakwana",
    "Rambukkana",
    "Ranna",
    "Rathnapura",
    "Ratmalana",
    "Rikillagskada",
    "Ruwanwella",
    "Seeduwa",
    "Serunuwara",
    "Sigiriya",
    "Siyabalaanduwa",
    "Sooriyawewa",
    "Thalawathugoda",
    "Thambuthegama",
    "Thanamalwila",
    "Thangalle",
    "Thawalama",
    "Tissamaharamaya",
    "Trincomalee",
    "Udawalawa",
    "Ududumbara",
    "Udugama",
    "Unawatuna",
    "Urapola",
    "Urubokka",
    "Vavuniya",
    "Vellankulam",
    "Veyangoda",
    "Wadduwa",
    "Waduramba",
    "Walapane",
    "Walasmulla",
    "Warakapola",
    "Wariyapola",
    "Waskaduwa",
    "Wattala",
    "Weerawila",
    "Weligama",
    "Welimada",
    "Welisara",
    "Weliveriya",
    "Wellampitiya",
    "Wellawaya",
    "Wennappuwa",
    "Wijerama",
    "Yakkala",
    "Yala",
    "Yatiyanthota",
  ];
  
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  // Reset modal state
  const resetModal = () => {
    setPasswords({
      currentPassword: "",
      newPassword: "",
      confirmPassword: "",
    });
    setVisibility({
      currentPassword: false,
      newPassword: false,
      confirmPassword: false,
    });
  };

  const getCustomerDetails = async () => {
    try {
      const response = await UserRequest.get("/Customer");
      const { firstName, lastName, address, city, contact, email } = response.data.data;
      setUserDetails({
        firstName,
        lastName,
        address,
        city,
        contact,
        email,
        password: "******", // Masking password as it's not retrieved
      });
    } catch (error) {
      console.error("Failed to fetch customer details", error);
    }
  };

  useEffect(() => {
    getCustomerDetails();
  }, []);

  // Generic input change handler
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
    setUserDetails({ ...userDetails, [field]: e.target.value });
  };

  // Generic select change handler for dropdowns
  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>, field: string) => {
    setUserDetails({ ...userDetails, [field]: e.target.value });
  };


  const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, field: PasswordFields) => {
    setPasswords((prev) => ({ ...prev, [field]: e.target.value }));
  };

  // Toggling visibility based on specific field
  const togglePasswordVisibility = (field: PasswordFields) => {
    setVisibility((prev) => ({ ...prev, [field]: !prev[field] }));
  };

  // Function to handle closing the modal
  const handleClose = () => {
    setOpen(false); // Close the modal by setting 'open' to false
    resetModal(); // Optionally reset the modal state if needed
  };

  // Function to handle opening the modal
  const handleOpen = () => {
    setOpen(true); // Open the modal by setting 'open' to true
  };


  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        setIsLoading(true);
        const response = await UserRequest.post("/Customer/update-password", {
          password: passwords.currentPassword,
          NewPassword: passwords.confirmPassword,
        });
        toast.success(response.data.message);
        handleClose();
      } catch (error: any) {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const validateForm = (): boolean => {
    const { currentPassword, newPassword, confirmPassword } = passwords;

    if (!currentPassword || !newPassword || !confirmPassword) {
      toast.error("All fields are required.");
      return false;
    }

    if (newPassword.length < 8) {
      toast.error("Password must be at least 8 characters.");
      return false;
    }

    if (newPassword !== confirmPassword) {
      toast.error("Passwords do not match.");
      return false;
    }

    if (!/^\S+$/.test(newPassword)) {
      toast.error("Passwords must not contain spaces.");
      return false;
    }

    return true;
  };

  const handleUpdateDetails = async () => {
    // Validate name, contact (phone), and email
    if (!validateDetails()) {
      return;
    }

    try {
      setIsLoading(true); // Show loading state
      const response = await UserRequest.post("/Customer", {
        firstName: userDetails.firstName,
        lastName: userDetails.lastName,
        address: userDetails.address,
        city: userDetails.city,
        contact: userDetails.contact,
        email: userDetails.email,
      });

      // If successful, show success message
      toast.success(response.data.message);
      getCustomerDetails();
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.message); // Show error message
      }
    } finally {
      setIsLoading(false); // End loading state
    }
  };

  // Validation function for name, contact, and email
  const validateDetails = (): boolean => {
    const { firstName, lastName, address, city, contact, email } = userDetails;

    // Name validation: must not be empty
    if (!(firstName?.trim())) {
      toast.error("Name is required.");
      return false;
    }

    // Name validation: must not be empty
    if (!(lastName?.trim())) {
      toast.error("Last Name is required.");
      return false;
    }

    // Name validation: must not be empty
    if (!(address?.trim())) {
      toast.error("Address is required.");
      return false;
    }

    // Name validation: must not be empty
    if (!(city?.trim())) {
      toast.error("City is required.");
      return false;
    }

    // Contact validation: basic phone number validation (you can adjust the regex as needed)
    const phoneRegex = /^[0-9]{10,15}$/; // Allow phone numbers between 10 and 15 digits
    if (!phoneRegex.test(contact)) {
      toast.error("Please enter a valid phone number.");
      return false;
    }

    // Email validation: basic email regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Please enter a valid email address.");
      return false;
    }

    // If all validations pass
    return true;
  };


  return (
    <div className="flex flex-col justify-between bg-dark-blue min-h-dvh">
      <div className="w-full relative flex flex-row items-start justify-center gap-[9.375rem]">
        <div className="w-fit h-full flex flex-col items-start justify-center pt-[3.381rem] pb-[128px]">
          <Navbar />
          <div className=" w-5/12 mq750:w-full flex flex-col gap-10 px-5 md:px-0">
            <div className="flex flex-col gap-6">
              <img
                onClick={() => navigate(-1)}
                className="cursor-pointer w-[1.5rem] h-[1.5rem] relative overflow-hidden shrink-0 object-contain"
                loading="lazy"
                alt=""
                src="/ggchevrondowno1@2x.png"
              />
              <h2 className="title-gradient m-0">Account Details</h2>
            </div>
            <div className="flex flex-col items-start justify-start gap-[1rem] w-full">
              <input
                type="text"
                value={userDetails.firstName}
                name="firstName"
                onChange={(e) => handleInputChange(e, "firstName")}
                placeholder="First Name"
              />
              <input
                type="text"
                value={userDetails.lastName}
                name="lastName"
                onChange={(e) => handleInputChange(e, "lastName")}
                placeholder="Last Name"
              />
              <input
                type="text"
                value={userDetails.address}
                name="address"
                onChange={(e) => handleInputChange(e, "address")}
                placeholder="Address"
              />
              <select
                value={userDetails.city}
                name="city"
                onChange={(e) => handleSelectChange(e, "city")}
              >
                <option value="" disabled>Select your city</option>
                {citiesInSriLanka.map(city => (
                  <option key={city} value={city}>{city}</option>
                ))}
              </select>
              <input
                type="text"
                name="contact"
                placeholder="Contact"
                value={userDetails.contact}
                onChange={(e) => handleInputChange(e, "contact")}
              />
              <input
                type="email"
                name="email"
                placeholder="Email"
                value={userDetails.email}
                onChange={(e) => handleInputChange(e, "email")}
              />
              <div className="relative self-stretch flex items-center gap-3 w-full justify-between">
                <input
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={userDetails.password}
                  readOnly
                />
                <p onClick={() => setOpen(true)} className="m-0 text-pink cursor-pointer absolute right-5 top-[16px]">
                  Change
                </p>
              </div>
            </div>
            <Button btnText="Update Details" isLoading={isLoading} click={handleUpdateDetails} />
          </div>
        </div>
      </div>
      <FooterHome />

      {/* Change Password Modal */}
      <Modal open={open} onClose={() => setOpen(false)} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
        <Box sx={style} className={`p-16 lg:p-5 lg:w-full`}>
          <p className="m-0 text-11xl relative font-chivo leading-[120%] text-transparent !bg-clip-text [background:linear-gradient(89.74deg,_#baffd4,_#82f8ff_40.33%,_#ffd3d8)]">
            Update your password
          </p>
          <p className="text-white text-xl font-light">Enter your current password and add a new password</p>
          <form autoComplete="off" onSubmit={handleSubmit}>
            {["currentPassword", "newPassword", "confirmPassword"].map((field) => (
              <TextField
                key={field}
                variant="outlined"
                name={field}
                placeholder={
                  field === "currentPassword"
                    ? "Current Password"
                    : field === "newPassword"
                      ? "New Password"
                      : "Confirm New Password"
                }
                value={passwords[field as PasswordFields]} // Explicitly cast 'field' to 'PasswordFields'
                onChange={(e) => handlePasswordChange(e, field as PasswordFields)} // Ensure 'field' is treated as 'PasswordFields'
                className="mb-5 w-full font-semibold bg-inherit self-stretch flex justify-start"
                InputProps={{
                  sx: {
                    "& .MuiInputBase-input": { color: "#fff", width: "100%" },
                    "& .MuiOutlinedInput-notchedOutline": { borderRadius: "100px", border: "1px solid #fff" },
                  },
                  type: visibility[field as PasswordFields] ? "text" : "password", // Ensure 'field' is treated as 'PasswordFields'
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => togglePasswordVisibility(field as PasswordFields)} aria-label="toggle password visibility">
                        <Icon color="primary">{visibility[field as PasswordFields] ? "visibility_off" : "visibility"}</Icon>
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            ))}

            <Button btnText={isLoading ? "Loading..." : "Done"} isLoading={isLoading} />
            <Button btnText="Cancel" click={() => setOpen(false)} />
          </form>
        </Box>
      </Modal>
    </div>
  );
}


const style = {
  position: "absolute" as "absolute",
  textAlign: "center",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "#1F2B49",
  border: "2px solid #1F2B49",
  borderRadius: "18px",
  boxShadow: 24,
  color: "#fff",
};
