import { FunctionComponent } from "react";
import Navbar from "../components/Navbar";
import FooterHome from "../components/FooterHome";

const TermsConditions: FunctionComponent = () => {
    return (
        <section className="w-full flex flex-col items-center relative bg-dark-blue pt-[3.25rem]">
            <Navbar />
            <div className="w-[71rem] lg:w-full flex flex-row mx-auto relative h-full items-center lg:px-[1.25rem]">
                <h2 className="title-gradient">Terms and Conditions</h2>
            </div>
            <div className="content w-[71rem] lg:w-full text-white lg:px-[1.25rem]">
                <p className="body">These Terms and Conditions govern the use of the Venu Technologies platform. By using our platform to book sports venues, you agree to the following terms:</p>
                
                <h4>1. Booking Process</h4>
                <ul>
                    <li className="body">Account Creation: To make a booking, you must create an account with accurate details.</li>
                    <li className="body">Booking Confirmation: Bookings are only confirmed once payment has been successfully processed through the card payment system. Until payment is received, the booking remains unconfirmed.</li>
                    <li className="body">Amendments or Cancellations: While there are no penalties for cancellations, we prefer cancellations to be made at least 48 hours prior to the scheduled booking. However, there is no penalty for late cancellations at this stage of our business growth.</li>
                </ul>
                
                <h4>2. Pricing</h4>
                <ul>
                    <li className="body">Commission: A 5% commission is charged to the customer for every booking.</li>
                </ul>

                <h4>3. Payment Terms</h4>
                <ul>
                    <li className="body">Payments are processed through secure third-party payment gateways. By using Venu, you agree to the payment terms provided by our payment partners.</li>
                    <li className="body">No additional payment gateway charges will be passed to the customer. These are covered by the 5% commission charged to the customer.</li>
                </ul>
                
                <h4>4. Responsibilities and Obligations</h4>
                <ul>
                    <li className="body">You are responsible for providing accurate information during booking and ensuring compliance with the terms of the venues you book.</li>
                </ul>
                
                <h4>5. Limitation of Liability</h4>
                <ul>
                    <li className="body">Venu Technologies is not responsible for any damages or losses resulting from using the platform, including issues related to venue availability or quality.</li>
                </ul>

                <h4>6. Refund Processing</h4>
                <p className="body">Refunds for eligible bookings will be processed within 5-7 business days of receiving the refund request.</p>
            </div>
            <FooterHome />
        </section>
    )
}

export default TermsConditions;