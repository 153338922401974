import React, { useState } from 'react';
import { Modal, Box, TextField, Button } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AuthRequest from '../services/authRequest';
import UserRequest from '../services/userRequest';

interface ReviewModalProps {
    open: boolean;
    onClose: () => void;
    bookingId: number;
}

interface ReviewData {
    rating: string;
    title: string;
    description: string;
    submit: string;
}

const ReviewModal: React.FC<ReviewModalProps> = ({ open, onClose, bookingId }) => {
    const [rating, setRating] = useState<number>(0);
    const [title, setTitle] = useState<string>('');
    const [review, setReview] = useState<string>('');
    const [errors, setErrors] = useState<Partial<ReviewData>>({});
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const [hover, setHover] = useState<number>(0);

    const validateForm = (): boolean => {
        const newErrors: Partial<ReviewData> = {};

        if (rating === 0) {
            newErrors.rating = 'Please select a rating';
        }
        if (!title.trim()) {
            newErrors.title = 'Please enter a title';
        }
        if (!review.trim()) {
            newErrors.description = 'Please enter your review';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async () => {
        if (!validateForm()) {
            return;
        }

        setIsSubmitting(true);

        const body = {
            'bookingId': bookingId,
            'rating': rating,
            'title': title,
            'description': review,
        };

        try {
            const response = await UserRequest.post('/Reviews/customer', body)
            const data = response.data.data;
            console.log('data', data);

            // Success
            onClose();
            setRating(0);
            setTitle('');
            setReview('');
            setErrors({});

            // You might want to add a success toast/notification here
        } catch (error) {
            console.error('Error submitting review:', error);
            setErrors({
                ...errors,
                submit: 'Failed to submit review. Please try again.'
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleClose = () => {
        setRating(0);
        setTitle('');
        setReview('');
        setErrors({});
        onClose();
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="review-modal"
            className="flex items-center justify-center"
        >
            <Box className="bg-[#1e2536] rounded-lg p-8 w-full max-w-md mx-4">
                <h2 className="text-2xl text-white mb-6">Add Review</h2>

                {/* Star Rating */}
                <div className="flex flex-col mb-6">
                    <div className="flex items-center">
                        {[1, 2, 3, 4, 5].map((index) => (
                            <Box
                                key={index}
                                className="cursor-pointer"
                                onMouseEnter={() => setHover(index)}
                                onMouseLeave={() => setHover(rating)}
                                onClick={() => {
                                    setRating(index);
                                    setErrors({ ...errors, rating: undefined });
                                }}
                            >
                                {index <= (hover || rating) ? (
                                    <StarIcon sx={{ color: '#ff9cba', fontSize: 32, marginRight: '4px' }} />
                                ) : (
                                    <StarBorderIcon sx={{ color: '#ff9cba', fontSize: 32, marginRight: '4px' }} />
                                )}
                            </Box>
                        ))}
                        <span className="ml-2 text-white">
                            {rating > 0 ? `${rating} STARS` : ''}
                        </span>
                    </div>
                    {errors.rating && (
                        <span className="text-red-500 text-sm mt-1">{errors.rating}</span>
                    )}
                </div>

                {/* Title Input */}
                <TextField
                    fullWidth
                    placeholder="Enter title"
                    variant="outlined"
                    className="mb-4"
                    value={title}
                    onChange={(e) => {
                        setTitle(e.target.value);
                        setErrors({ ...errors, title: undefined });
                    }}
                    error={!!errors.title}
                    helperText={errors.title}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            '& fieldset': {
                                borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                                borderColor: 'rgba(255, 255, 255, 0.2)',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'rgba(255, 255, 255, 0.3)',
                            },
                        },
                        '& .MuiOutlinedInput-input': {
                            color: 'white',
                        },
                        '& .MuiFormHelperText-root': {
                            color: '#ef4444',
                        },
                    }}
                />

                {/* Review Text Area */}
                <TextField
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="Share details of your experience at this venue"
                    variant="outlined"
                    className="mb-6"
                    value={review}
                    onChange={(e) => {
                        setReview(e.target.value);
                        setErrors({ ...errors, description: undefined });
                    }}
                    error={!!errors.description}
                    helperText={errors.description}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: 'rgba(255, 255, 255, 0.1)',
                            '& fieldset': {
                                borderColor: 'transparent',
                            },
                            '&:hover fieldset': {
                                borderColor: 'rgba(255, 255, 255, 0.2)',
                            },
                            '&.Mui-focused fieldset': {
                                borderColor: 'rgba(255, 255, 255, 0.3)',
                            },
                        },
                        '& .MuiOutlinedInput-input': {
                            color: 'white',
                        },
                        '& .MuiFormHelperText-root': {
                            color: '#ef4444',
                        },
                    }}
                />

                {errors.submit && (
                    <div className="text-red-500 text-sm mb-4">{errors.submit}</div>
                )}

                {/* Action Buttons */}
                <div className="flex justify-end gap-4">
                    <Button
                        onClick={handleClose}
                        className="text-[#ff9cba] hover:bg-[#ff9cba] hover:bg-opacity-10"
                        disabled={isSubmitting}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        onClick={handleSubmit}
                        disabled={isSubmitting}
                        className="bg-[#e3f5f1] text-[#1e2536] hover:bg-[#cce8e3]"
                    >
                        {isSubmitting ? 'Submitting...' : 'Add a Review'}
                    </Button>
                </div>
            </Box>
        </Modal>
    );
};

export default ReviewModal;