// Navbar.tsx
import React from 'react';
import { FunctionComponent } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthStatus from './AuthStatus'; // Import the AuthStatus component
import { Dropdown } from '@mui/base/Dropdown';

import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ArticleIcon from '@mui/icons-material/Article';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import Button from './Button';

const Navbar: FunctionComponent = () => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleAccount = () => {
        navigate('/account');
    };

    const handleBookings = () => {
        navigate('/bookings');
    };

    const handleSignOut = () => {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/login');
    }

    return (
        <div className="w-full flex flex-col items-center justify-center gap-[4.563rem_0rem] lg:px-[1.25rem]">
            <div className="lg:w-full w-[71rem] h-[5.688rem] flex flex-col items-center">
                <header className="w-full flex flex-row items-end justify-between gap-[1.25rem] text-center">
                    <Link to={'/'}>
                        <img
                            className="h-[2.138rem] w-[7.419rem] relative"
                            loading="lazy"
                            alt=""
                            src="/venu-logo.svg"
                        />
                    </Link>
                    <AuthStatus>
                        {(isLoggedIn, firstName) => isLoggedIn ? (
                            <div id="loggedIn" className="w-full flex flex-row items-center justify-end gap-[0rem_1rem]">
                                <div className="flex justify-end items-center relative text-center text-white label1">
                                    Game On, {firstName}
                                    <Tooltip title="Account settings">
                                        <IconButton
                                            onClick={handleClick}
                                            size="small"
                                            sx={{ ml: 2 }}
                                            aria-controls={open ? 'account-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={open ? 'true' : undefined}
                                        >
                                            <Avatar sx={{ width: 32, height: 32 }}></Avatar>
                                        </IconButton>
                                    </Tooltip>
                                </div>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={open}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            background: '#fff',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            padding: '8px 12px',
                                            borderRadius: '16px',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 18,
                                                width: 10,
                                                height: 10,
                                                bgcolor: '#fff',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                                    anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                                >
                                    <MenuItem onClick={handleAccount} className='flex mb-2 text-navy-blue gap-3 body p-0'>
                                        <img src="/account_icon.svg" alt="" /> Manage Account
                                    </MenuItem>
                                    <MenuItem onClick={handleBookings} className='flex mb-2 text-navy-blue gap-3 body p-0'>
                                        <img src="/booking_icon.svg" alt="" /> Your Bookings
                                    </MenuItem>
                                    {/* <MenuItem onClick={handleClose} className='flex border border-solid border-[#fff] text-base px-4 py-5 rounded-lg justify-between text-transparent !bg-clip-text [background:linear-gradient(89.74deg,_#baffd4,_#82f8ff_40.33%,_#ffd3d8)]'>
                                        Invite a Friend <img src="/invite-icon.svg" alt="" />
                                    </MenuItem>
                                    <MenuItem onClick={handleClose} className='text-[#FB709A] underline mt-8'>
                                        Privacy
                                    </MenuItem> */}
                                    <MenuItem onClick={handleSignOut} className='flex text-navy-blue gap-3 body p-0'>
                                        <img src="/sign_out_icon.svg" alt="" /> Sign out
                                    </MenuItem>
                                </Menu>
                            </div>
                        ) : (
                            <div id="loggedOut" className="w-[13rem] flex flex-row gap-5 items-center justify-end">
                                <Link to="/login" className="cursor-pointer [border:none] px-4">
                                    <div className="body interactive-gradient">
                                        Login
                                    </div>
                                </Link>
                                <Link to="/signup">
                                    <Button btnText='Sign up' classes='!py-2 !px-4 !mt-0 !w-fit' />
                                </Link>
                            </div>
                        )}
                    </AuthStatus>
                </header>
            </div>
        </div>
    );
}

export default Navbar;
