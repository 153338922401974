import React, { FunctionComponent, useEffect, useState } from "react";
// import { Button } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthRequest from "../services/authRequest";
import { toast } from "react-toastify";
import UserRequest from "../services/userRequest";
import Button from "./Button";
import { API_URL, SANDBOX } from "../config";
import AuthStatus from "./AuthStatus";

interface BookingData {
  courtName: string;
  selectedDates: string[];
  selectedDate: string;
  selectedTimes: string[];
  sportTypeName: string;
  earliest: string;
  latest: string;
  totalPrice: number;
  activityId: string;
  locationId: string;
  repeat: boolean;
  date: string;
}

interface PayHereModel {
  merchantId: string;
  hash: string;
  orderId: string;
  orderName: string;
  currency: string;
  amount: string;
}

declare global {
  interface Window {
    payhere: any;
  }
}

interface BookingLocationProps {
  name: string;
  address: string;
}

const ConfirmBookingComp: FunctionComponent<BookingLocationProps> = ({
  name,
  address,
}) => {
  const [searchParams] = useSearchParams();
  const [locationId] = useState(searchParams.get("locationId"));
  const [activityId, setActivityId] = useState(searchParams.get("activityId"));
  const [bookingData, setBookingData] = useState<BookingData | null>(null);
  const [paymentId, setPaymentId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isPayHereLoaded, setIsPayHereLoaded] = useState(false);
  const [handlingFee, setHandlingFee] = useState<string>("");
  const navigate = useNavigate();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.payhere.lk/lib/payhere.js";
    script.async = true;

    script.onload = () => {
      if (window.payhere) {
        setIsPayHereLoaded(true);

        window.payhere.onCompleted = (orderId: any) => {
          navigate(`/booking-final?paymentLink=${orderId}`);
        };

        window.payhere.onDismissed = () => {
          console.log("Payment dismissed");
        };

        window.payhere.onError = (error: any) => {
          toast.error("Error with payhere");
          console.log("Error:" + error);
        };
      } else {
        console.error(
          "PayHere script loaded, but payhere object is not available."
        );
      }
    };

    script.onerror = () => {
      console.error("Failed to load the PayHere script.");
    };

    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const getBookingDetails = () => {
    const storedData = localStorage.getItem("bookingData");
    if (storedData) {
      setBookingData(JSON.parse(storedData));
      // Handling fee = 5% of total price
      setHandlingFee(
        ((JSON.parse(storedData).totalPrice * 5) / 100).toString()
      );
      console.log(JSON.parse(storedData));
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const paymentId = searchParams.get("paymentLink") as string;
    if (paymentId !== "" && paymentId != undefined && paymentId !== null) {
      setPaymentId(paymentId);
      getPamentDetails(paymentId);
    } else {
      getBookingDetails();
    }
  }, [location]);

  const getPamentDetails = async (paymentId: string) => {
    try {
      const response = await AuthRequest.get(
        `/Booking/customer/paymentLink?paymentLink=${paymentId}`
      );
      const data: BookingData = response.data.data;
      setBookingData(data);
      // Handling fee = 5% of total price
      setHandlingFee(((data.totalPrice * 5) / 100).toString());
    } catch (error) {
      console.log(error);
    }
  };

  const TimeComponent = (datetimeString: string) => {
    return datetimeString.split("T")[1].split("+")[0].slice(0, 5);
  };

  const getBeforeFirstSlash = () => {
    const url = window.location.href;
    const firstSlashIndex = url.indexOf("/", url.indexOf("//") + 2); // Find the first slash after the protocol (e.g., https://)
    return url.substring(0, firstSlashIndex);
  };

  const handlePayment = (payhereDetails: PayHereModel) => {
    if (payhereDetails) {
      const payment = {
        sandbox: SANDBOX,
        merchant_id: payhereDetails.merchantId, // Replace your Merchant ID
        return_url: `${API_URL}/booking-final?id=${payhereDetails.orderId}`, // Important
        cancel_url: window.location.href, // Important
        notify_url: `${API_URL}/booking/customer/verify-booking`,
        order_id: payhereDetails.orderId,
        items: payhereDetails.orderName,
        amount: payhereDetails.amount,
        currency: payhereDetails.currency,
        hash: payhereDetails.hash, // *Replace with generated hash retrieved from backend
        first_name: localStorage.getItem("firstName"),
        last_name: localStorage.getItem("lastName"),
        email: localStorage.getItem("email"),
        phone: localStorage.getItem("contact"),
        address: localStorage.getItem("address"),
        city: localStorage.getItem("city"),
        country: "Sri Lanka",
      };
      if (isPayHereLoaded && window.payhere) {
        window.payhere.startPayment(payment);
      } else {
        console.error(
          "PayHere script not loaded or payhere object not available"
        );
      }
    } else {
      toast.error("Error Making Payment");
    }
  };

  const createCustomerBooking = async () => {
    if (bookingData) {
      const today = new Date();
      const dateStr = today.toISOString().split("T")[0]; // Extracts the date part
      // Combine the date with the times
      const startTime = `${dateStr}T${bookingData.earliest}:00.000Z`;
      const endTime = `${dateStr}T${bookingData.latest}:00.000Z`;
      const verifyBooking = await UserRequest.post(
        "/booking/customer/booking-available",
        {
          startTime: startTime,
          endTime: endTime,
          activityId: bookingData.activityId,
          locationId: bookingData.locationId,
          date: bookingData.date,
          repeat: bookingData.repeat ? "Monthly" : "None",
        }
      );
      if (verifyBooking.data?.data?.length > 0) {
        const response = await UserRequest.post(
          "/booking/customer/add-booking",
          {
            startTime: startTime,
            endTime: endTime,
            activityId: bookingData.activityId,
            locationId: bookingData.locationId,
            date: bookingData.date,
            repeat: bookingData.repeat ? "Monthly" : "None",
          }
        );
        const data = response.data.data;
        // move the cert encrypting to the backend
        handlePayment(data);
      } else {
        toast.error("Already Bookings available");
      }
    }
  };

  const getPamentDetailsWithLink = async (paymentId: string) => {
    try {
      const response = await AuthRequest.get(
        `/Booking/customer/payment-details?paymentLink=${paymentId}`
      );
      const data = response.data.data;
      handlePayment(data);
    } catch (error) {
      console.log(error);
    }
  };

  const payNow = async () => {
    if (!paymentId && bookingData) {
      try {
        setIsLoading(true);
        await createCustomerBooking();
        // Redirect the user to the homepage
      } catch (error: any) {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      } finally {
        setIsLoading(false);
      }
    } else if (paymentId && bookingData) {
      await getPamentDetailsWithLink(paymentId);
    } else {
      toast.error("Error Making Booking");
    }
  };

  const completeLogin = () => {
    localStorage.setItem(
      "resumePath",
      `confirm-booking?locationId=${locationId}&activityId=${activityId}`
    );
    navigate("/login");
  };

  function formatDate(dateStr: string): string {
    if (dateStr.includes("-")) {
      // Handle "DD-MM-YYYY" format
      const [day, month, year] = dateStr.split("-");
      const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));

      // Format to "DD MMM"
      return date
        .toLocaleDateString("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        })
        .replace(/ /g, " ");
    } else if (dateStr.match(/^\d{1,2} [A-Za-z]{3}$/)) {
      // If it's already in "DD MMM" format, return as is
      return dateStr;
    } else {
      // Handle invalid format
      throw new Error(
        "Invalid date format. Please use 'DD-MM-YYYY' or 'DD MMM'."
      );
    }
  }

  return (
    <div className="w-full flex flex-col items-start justify-start gap-[4rem_0rem] text-white">
      <div className="self-stretch flex flex-col items-end justify-start gap-[1.75rem_0rem] max-w-full">
        {bookingData ? (
          <div className="booking-details w-full">
            <div className="venue">
              <p className="label2 uppercase title-gradient">
                You will be playing at:
              </p>
              <h2 className="m-0">{name}</h2>
            </div>
            <div className="court flex flex-col gap-2 pt-8 pb-8">
              <p className="label2 uppercase title-gradient">Court:</p>
              <p className="body m-0">{bookingData.courtName}</p>
              <div className="flex gap-2 items-center">
                {bookingData.selectedDates.map((date, index) => (
                  <React.Fragment key={date}>
                    <p className="body m-0">{formatDate(date)}</p>
                    {index < bookingData.selectedDates.length - 1 && (
                      <span>&nbsp;|&nbsp;</span>
                    )}
                  </React.Fragment>
                ))}
              </div>
              <p className="body m-0">
                {bookingData.earliest} - {bookingData.latest}
              </p>
            </div>
            <div className="flex w-fit">
              <Button
                btnText="Edit your booking"
                click={() =>
                  navigate(
                    `/select-sport?locationId=${locationId}&activityId=${activityId}&edit=true`
                  )
                }
                classes="!py-2 !px-4 !mt-0 !w-fit"
              />
            </div>
            <div className="flex flex-col gap-3 py-[56px]">
              <div className="cost flex gap-6 items-center">
                <p className="body m-0">Subtotal</p>
                <h4 className="m-0">{bookingData.totalPrice} LKR</h4>
              </div>
              <div className="cost flex gap-6 items-center">
                <p className="body m-0">Handling Fee</p>
                <h4 className="m-0">{bookingData.totalPrice * 0.05} LKR</h4>
              </div>
              <div className="cost flex gap-6 items-center">
                <p className="body m-0">Total Cost</p>
                <h4 className="m-0">
                  {bookingData.totalPrice + parseInt(handlingFee)} LKR
                </h4>
              </div>
            </div>
            <div className="flex w-[360px] md:w-full">
              <AuthStatus>
                {(isLoggedIn, firstName) =>
                  isLoggedIn ? (
                    <Button click={payNow} btnText="Pay Now" />
                  ) : (
                    <Button
                      click={completeLogin}
                      btnText="Login to complete your booking"
                    />
                  )
                }
              </AuthStatus>
            </div>
            <p className="body">
              Once your booking is confirmed, cancellations are not permitted.
              Please confirm your details before proceeding to make the payment.
            </p>
          </div>
        ) : (
          <p>No data found.</p>
        )}
      </div>
    </div>
  );
};

export default ConfirmBookingComp;
