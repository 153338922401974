import { FormEvent, FunctionComponent, useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthRequest from "../services/authRequest";
import { toast } from "react-toastify";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { VisibilityOff } from "@mui/icons-material";
import Button from "../components/Button";

interface FormData {
  email: string;
  password: string;
}

const PlayerLogin: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [path, setPath] = useState('');
  const [formData, setFormData] = useState<FormData>({
    email: "",
    password: "",
  });

  useEffect(() => {
    let path = localStorage.getItem('resumePath');
    setPath(path ? path : '');
  }, [])

  function validateForm(email: string, password: string): boolean {
    if (!email || !password) {
      toast.error("All fields are required.");
      return false;
    }
    // Add more validation checks as needed
    return true;
  }

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (validateForm(formData.email, formData.password)) {
      try {
        setIsLoading(true);
        const response = await AuthRequest.post("/Auth/customer/authenticate", {
          email: formData.email,
          password: formData.password,
        });

        // console.log(response.data);
        if (response && response.data) {
          // Store the token and refreshToken in session storage
          sessionStorage.setItem("token", response.data.data.token);
          sessionStorage.setItem("refreshToken", response.data.data.refreshToken);

          // Save the email, firstName, and id in local storage
          localStorage.setItem("email", response.data.data.details.email);
          localStorage.setItem("firstName", response.data.data.details.firstName);
          localStorage.setItem("lastName", response.data.data.details.lastName);
          localStorage.setItem("contact", response.data.data.details.contact);
          localStorage.setItem("address", response.data.data.details?.address);
          localStorage.setItem("city", response.data.data.details?.city);
          localStorage.setItem("id", response.data.data.details.id.toString());

          // Use React Toastify to show a success message
          toast.success(response.data.message);

          // Redirect the user to the homepage
          let navigationPath = path ? '/' + path : '/'
          navigate(navigationPath);
        }
      } catch (error: any) {
        if (error.response) {
          toast.error(error.response.data.message);
        }
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleVisible = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <div className="bg-dark-blue">
      <div className="container flex flex-col min-h-dvh pt-14">
        <div className="w-full flex">
          <div className="w-1/12 lg:hidden"></div>
          <div className="w-11/12 lg:w-full">
            <img
              className="w-[7.419rem] h-[2.138rem] cursor-pointer"
              alt=""
              src="/venu-logo.svg"
              onClick={() => navigate("/")}
            />
          </div>
        </div>
        <div className="w-full flex flex-1 items-center">
          <div className="w-1/12 lg:hidden"></div>
          <div className="w-5/12 lg:w-full h-full">
            <div className="flex flex-col gap-[1.75rem] w-full">
              <h2 className="title-gradient">{path ? `Login to complete your booking` : `Welcome back`}</h2>
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                className="self-stretch flex flex-col justify-start pt-[0rem] px-[0rem] pb-[0.438rem] box-border gap-[0.625rem] max-w-full text-pink"
              >
                <div className="self-stretch flex flex-col items-start justify-start gap-[1rem] max-w-full">
                  <input
                    type="email"
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                    placeholder="Email"
                  />
                  <div className="relative self-stretch flex w-full justify-between">
                    <input
                      type={isPasswordVisible ? "text" : "password"}
                      value={formData.password}
                      name="password"
                      onChange={handleChange}
                      placeholder="Password"
                    />
                    <span onClick={handleVisible}>
                      {isPasswordVisible ? (
                        <VisibilityOff className="absolute right-5 top-[16px]" />
                      ) : (
                        <VisibilityIcon className="absolute right-5 top-[16px]" />
                      )}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row items-start py-[0rem] px-[0.125rem]">
                  <div className="relative">
                    <a
                      href="/forgot-password"
                      className="flex-1 body text-pink no-underline"
                    >
                      Forgot Password
                    </a>
                  </div>
                </div>
                <Button isLoading={isLoading} btnText={path ? `Login to complete your booking` : `Login`} />
                <div className="flex flex-col gap-4 mt-8">
                  <div className="flex items-center gap-5 justify-center">
                    <img
                      className="w-fit"
                      loading="lazy"
                      alt=""
                      src="/vector-170.svg"
                    />
                    <p className="label2 uppercase text-white m-0">OR</p>
                    <img
                      className="w-fit"
                      loading="lazy"
                      alt=""
                      src="/vector-170.svg"
                    />
                  </div>
                  <div className="flex gap-2 justify-center pt-4">
                    <p className="m-0 text-white label1">Are you new?</p>
                    <Link className="body" to={'/signup'}>Create an Account</Link>
                  </div>
                  <div className="flex gap-2 justify-center">
                    <p className="m-0 text-white label1">Do you own a venue?</p>
                    <Link className="body" to={'https://admin.venu.lk/'}>Learn more</Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="w-1/12 lg:hidden"></div>
          <div className="w-5/12 lg:hidden h-full flex self-end">
            <img src="/image-1888@2x.png" className="w-full" alt="" />
          </div>
        </div>
      </div >
    </div >
  );
};

export default PlayerLogin;
