import { TextField, InputAdornment, Icon, IconButton } from "@mui/material";
import { Link } from "react-router-dom";
import Button from "./Button";

interface LocationType {
  id: number;
  name: string;
  address: string;
  openTime: string;
  closeTime: string;
  rating: number;
  isListed: boolean;
  requestedCount: number;
  activityList: ActivityType[];
  mapLink: string;
  contact: string;
}

interface ActivityType {
  price: number;
  typeName: string;
  id: number;
  name: string;
}

interface LocationListProps {
  listedLocations: LocationType[];
  unlistedLocations: LocationType[];
}

const IndividualListing: React.FC<LocationListProps> = ({
  listedLocations,
  unlistedLocations,
}) => {
  return (
    <div className="w-[71rem] lg:w-full mb-8 flex flex-wrap items-start gap-x-[4.875rem] max-w-full text-white">
      <div className="w-7/12 lg:w-full flex flex-col items-start pt-[0.131rem]">
        <div className="w-full flex flex-col">
          {listedLocations.map((location) => (
            <ListedItem key={location.id} location={location} />
          ))}
          {unlistedLocations.map((location) => (
            <UnlistedItem key={location.id} location={location} />
          ))}
        </div>
      </div>
    </div>
  );
};

const ListedItem: React.FC<{ location: LocationType }> = ({ location }) => {
  return (
    <div className="w-full p-6 flex flex-col rounded-2xl bg-gravel mb-6 gap-8">
      <div className="w-full rounded-lg flex flex-row items-start justify-between">
        <div className="flex flex-col items-start">
          <div className="flex flex-col gap-y-[1rem]">
            <h2 className="title-gradient my-0">{location.name}</h2>
            <p className="body my-0">{location.address}</p>
          </div>
        </div>
        {/* Rating */}
        <div className="flex items-center gap-x-[0.438rem] text-[0.75rem]">
          <img
            className="w-[1rem] h-auto"
            loading="lazy"
            alt="star"
            src="/phstarfill.svg"
          />
          <span className="pt-[0.125rem]">{location.rating}</span>
        </div>
      </div>
      <div className="w-full flex flex-col gap-4">
        <div className="label2">Select a court</div>
        <div className="flex flex-col gap-2 justify-center w-full lg:w-full">
          {location.activityList.map((activity) => (
            <div
              key={activity.id}
              className="w-full courtFull border border-[#3B4765] border-solid bg-glass gap-2 px-4 py-1 flex rounded-lg items-center group"
            >
              <img
                className="h-[2.5rem] w-auto object-cover"
                loading="lazy"
                alt={activity.typeName}
                src={`/sportTypes/${activity.typeName.toLowerCase()}.png`}
              />
              <Link
                to={
                  `/select-sport?locationId=${location.id}&activityId=${activity.id}` ||
                  "/"
                }
                className="w-full"
              >
                <div className="w-full details flex flex-1 lg:flex-col justify-between items-center lg:items-start">
                  <h5 className="title-gradient group-hover:text-navy-blue my-0">
                    {activity.name}
                  </h5>
                  <p className="body price title-gradient my-0">
                    {activity.price.toLocaleString()} /hr
                  </p>
                  {/* <div className="courtPrice lg:w-full lg:justify-between flex gap-4 items-center absolute lg:relative right-0 py-[11px] px-2 lg:pl-0 lg:pb-0 lg:pt-1 rounded-md">
                  </div> */}
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const UnlistedItem: React.FC<{ location: LocationType }> = ({ location }) => {
  return (
    <div className="w-full p-6 flex flex-col rounded-2xl bg-gravel mb-6 gap-4">
      <div className="w-full rounded-lg flex flex-row items-baseline justify-between">
        <div className="flex flex-col items-start">
          <div className="flex flex-col gap-y-[1rem]">
            <h4 className="title-gradient my-0 pr-4">
              {location.mapLink ? (
                <Link
                  className="title-gradient my-0 pr-4"
                  to={location.mapLink}
                  target="_blank"
                >
                  {location.name ?? "N/A"}
                </Link>
              ) : (
                <span className="title-gradient my-0 pr-4">
                  {location.name ?? "N/A"}
                </span>
              )}
            </h4>
            {location.mapLink ? (
              <Link to={location.mapLink} target="_blank">
                <div className="map flex gap-2">
                  <img src="/map_icon.svg" alt="" />
                  <p className="body my-0 text-white">
                    {location.address ?? "N/A"}
                  </p>
                </div>
              </Link>
            ) : (
              <div className="map flex gap-2">
                <img src="/map_icon.svg" alt="" />
                <p className="body my-0 text-white">
                  {location.address ?? "N/A"}
                </p>
              </div>
            )}

            {location.contact ? (
              <Link to={`tel:${location.contact}`} target="_blank">
                <div className="phone flex gap-2">
                  <img src="/phone_icon.svg" alt="" />
                  <p className="body my-0 text-white">
                    {location.contact ?? "N/A"}
                  </p>
                </div>
              </Link>
            ) : (
              <div className="phone flex gap-2">
                <img src="/phone_icon.svg" alt="" />
                <p className="body my-0 text-white">
                  {location.contact ?? "N/A"}
                </p>
              </div>
            )}
          </div>
        </div>
        {/* Rating */}
        <div className="flex items-center gap-x-[0.438rem] text-[0.75rem]">
          <p className="label2 text-gravel1">Unlisted</p>
          {/* <img
            className="w-[1rem] h-auto"
            loading="lazy"
            alt="star"
            src="/phstarfill.svg"
          />
          <span className="pt-[0.125rem]">{location.rating}</span> */}
        </div>
      </div>
      {/* <div className="flex items-center gap-4">
        <div>
          <Button btnText="+ Invite to Venu" classes="flex text-nowrap !py-2 !px-4" />
        </div>
        <p className="label1 text-white w-full mt-4 m-0">{location.requestedCount} players have invited</p>
      </div> */}
    </div>
  );
};

export default IndividualListing;
