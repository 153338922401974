import { FunctionComponent } from "react";
import { Link } from "react-router-dom";

const FooterHome: FunctionComponent = () => {
  return (
    <footer className={`w-[71rem] lg:w-full mx-auto flex flex-row lg:flex-col items-start justify-between pt-[5.188rem] pb-[5.125rem] max-w-full gap-12 text-left text-pure-white px-[1.25rem]`}>
      <div className="flex flex-col items-start justify-start">
        <div className="flex flex-row items-start justify-start">
          <div className="flex-1 flex flex-col items-start justify-start">
            <p className="label2 title-gradient m-0 uppercase">
              for any inquiries
            </p>
          </div>
        </div>
        <img src="/contact-burhan.png" className="mt-6 mb-9 w-full" alt="" />
        <div className="flex flex-row lg:flex-col items-start justify-between gap-[1.25rem] text-[1rem]">
          <div className="label1">
            <a href="/privacy-policy" className="text-white">
              <p className="m-0">Privacy Policy</p>
            </a>
          </div>
          <div className="label1">
            <a href="/terms-and-conditions" className="text-white">
              <p className="m-0">Terms & Conditions</p>
            </a>
          </div>
          <div className="label1">
            <a href="/refunds" className="text-white">
              <p className="m-0">Refunds</p>
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col items-start justify-start">
        <div className="flex flex-col items-start justify-start gap-11">
          <img
            className="w-[8.306rem] h-[2.394rem] relative"
            loading="lazy"
            alt=""
            src="/venu.svg"
          />
          <div className="w-[3.563rem] h-[1.5rem] relative">
            <a href="https://www.instagram.com/venu.lk" target="_blank">
              <img
                className="absolute top-[0rem] left-[2.063rem] w-[1.5rem] h-[1.5rem] overflow-hidden"
                loading="lazy"
                alt=""
                src="/riinstagramfill.svg"
              />
            </a>
            <a href="https://www.linkedin.com/company/venu-tech/about/" target="_blank">
              <img
                className="absolute top-[0rem] left-[0rem] w-[1.5rem] h-[1.5rem] overflow-hidden"
                loading="lazy"
                alt=""
                src="/riinstagramfill-1.svg"
              />
            </a>
          </div>
          <div className="flex">
            <p className="m-0 text-white label1">
              <span className="opacity-50">© Copyright 2024 Venu Technologies <br />Designed by</span> <Link className="!opacity-100" to={'https://tandm.design/'} target="_blank">Tandm Studios</Link>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default FooterHome;