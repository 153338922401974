import { FunctionComponent } from "react";
import Navbar from "../components/Navbar";
import FooterHome from "../components/FooterHome";

const Privacy: FunctionComponent = () => {
    return (
        <section className="w-full flex flex-col items-center relative bg-dark-blue pt-[3.25rem]">
            <Navbar />
            <div className="w-[71rem] lg:w-full flex flex-row mx-auto relative h-full items-center lg:px-[1.25rem]">
                <h2 className="title-gradient">Privacy Policy</h2>
            </div>
            <div className="content w-[71rem] lg:w-full text-white lg:px-[1.25rem]">
                <h4>1. Introduction</h4>
                <p className="body">At Venu Technologies, we are committed to protecting the privacy and security of our users’ personal information. This Privacy Policy outlines how we collect, use, and safeguard your data when you interact with our platform, including booking sports facilities through Venu.</p>

                <h4>2. Information Collection</h4>
                <ul>
                    <li className="body">Personal Information: Name, email address, phone number, payment details, and location.</li>
                    <li className="body">Booking Data: Information about the bookings you make, including the venue, date, and time.</li>
                    <li className="body">Payment Information: We collect payment details required to process your bookings, such as credit card details, securely processed through our payment gateway partners.</li>
                </ul>

                <h4>3. Use of Information</h4>
                <ul>
                    <li className="body">To Process Bookings: We use your personal and payment details to manage and confirm your bookings.</li>
                    <li className="body">To Improve Services: We may use your booking history to improve our platform and provide personalized services.</li>
                    <li className="body">To Communicate: We may send emails or SMS updates about your bookings, new services, or offers.</li>
                </ul>

                <h4>4. Data Sharing</h4>
                <ul>
                    <li className="body">Payment Gateway: Your payment information is securely shared with our trusted payment gateway for processing transactions.</li>
                </ul>

                <h4>5. Security</h4>
                <p className="body">We implement robust security measures to protect your data. However, no system is 100% secure, and we cannot guarantee absolute security.</p>

                <h4>6. Your Rights</h4>
                <p className="body">You can request access to your data or request deletion at any time. If you have any concerns, please contact us.</p>

                <h4>7. Changes to This Privacy Policy</h4>
                <p className="body">
                    We reserve the right to update or change our Privacy Policy
                    at any time. We will notify you of any significant changes by posting the
                    new Privacy Policy on our site.</p>
                
                <h4>8. Contact Us</h4>
                <p className="body">If you have any questions about this Privacy Policy, please contact us at +94 77 462 2736</p>
            </div>
            <FooterHome />
        </section>
    )
}

export default Privacy;