import { FunctionComponent, useCallback, useEffect, useMemo, useState } from "react";
import Navbar from "../components/Navbar";
import FooterHome from "../components/FooterHome";
import BookingHeader from "../components/BookingHeader";
import BookingSlider from "../components/BookingSlider";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthRequest from "../services/authRequest";
import {
    Checkbox,
    createTheme,
    FormControl,
    FormControlLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    ThemeProvider
} from "@mui/material";
import { ActivityListProps, FormattedTimeSlot, TimeSlot } from "../types";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import SelectTiming from "../components/SelectTiming";
import { toast } from "react-toastify";
import Button from "../components/Button";

interface BookingLocationProps {
    name: string;
    address: string;
    contact: string;
}

interface Timing {
    startTime: string;
    endTime: string;
}

// Helper Functions
const formatTimeSlots = (timeSlots: TimeSlot[]): FormattedTimeSlot[] => {
    return timeSlots.map((slot) => ({
        startAt: slot.startAt.substring(11, 16),
        endAt: slot.endAt.substring(11, 16),
    }));
};

const timeToMinutes = (time: string): number => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
};

const getNextThreeWeeks = (selectedDate: Dayjs | null) => {
    if (!selectedDate) return [];
    return [
        selectedDate,
        selectedDate.add(1, "week"),
        selectedDate.add(2, "week"),
        selectedDate.add(3, "week"),
    ].map((date) => date.format("DD MMM"));
};

const findEarliestAndLatestTimes = (selectedTimings: Timing[]): { earliest: string | null; latest: string | null } => {
    if (selectedTimings.length === 0) return { earliest: null, latest: null };
    let earliest = selectedTimings[0].startTime;
    let latest = selectedTimings[0].endTime;

    selectedTimings.forEach((timing) => {
        if (timing.startTime < earliest) earliest = timing.startTime;
        if (timing.endTime > latest) latest = timing.endTime;
    });

    return { earliest, latest };
};

const SelectSport: FunctionComponent = () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const today = dayjs();
    const maxDate = dayjs().add(2, "month");
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const [listing, setListing] = useState<BookingLocationProps>();
    const [locationId] = useState(searchParams.get("locationId"));
    const [activityId, setActivityId] = useState(searchParams.get("activityId"));
    const [activityList, setActivityList] = useState<ActivityListProps[]>([]);
    const [date, setDate] = useState<Dayjs | null>(today);
    const [timings, setTimings] = useState<FormattedTimeSlot[]>([]);
    const [error, setError] = useState<string | null>(null);
    const [singlePrice, setSinglePrice] = useState<number>(0);
    const [repeatedDates, setRepeatedDates] = useState<string[]>([]);
    const [selectedTimings, setSelectedTimings] = useState<Timing[]>([]);
    const [repeatChecked, setRepeatChecked] = useState(false);

    const calculateTotalPrice = () => selectedTimings.length * singlePrice * (repeatChecked ? 4 : 1);

    // Load initial state from localStorage if edit mode is active
    useEffect(() => {
        const isEditMode = new URLSearchParams(location.search).get("edit") === "true";
        if (isEditMode) {
            const storedData = JSON.parse(localStorage.getItem("bookingData") || "{}");
            if (storedData) {
                setActivityId(storedData.activityId);
                setDate(dayjs(storedData.date));
                setSelectedTimings(storedData.selectedTimings || []);
                setRepeatChecked(storedData.repeat || false);
                setRepeatedDates(storedData.selectedDates || []);
                setSinglePrice(storedData.totalPrice / (selectedTimings.length || 1));  // Calculate single price
            }
        }
    }, [location.search]);

    const fetchInitialData = async () => {
        try {
            const [locationResponse, activityResponse] = await Promise.all([
                AuthRequest.get(`Location/customer/location-id?id=${locationId}`),
                AuthRequest.get(`Location/customer/activities-by-location?id=${locationId}`)
            ]);
            setListing(locationResponse.data.data);
            setActivityList(activityResponse.data.data);
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const handleChange = (e: SelectChangeEvent<string>) => {
        const newCourtId = e.target.value;
        setActivityId(newCourtId);
        const selectedSport = activityList.find((sport) => sport.id === Number(newCourtId));
        setSinglePrice(selectedSport?.price || 0);

        // Reset Other fields
        setDate(today);
        setTimings([]);
        setSelectedTimings([]);
    };

    const handleDateChange = useCallback(async () => {
        if (!date || activityId === "0") return;
        const formattedDate = date.format("YYYY-MM-DD");

        try {
            const response = await AuthRequest.post("Location/customer/available-times", {
                locationId,
                activityId,
                date: formattedDate,
            });
            setTimings(formatTimeSlots(response.data.data));
        } catch (error: any) {
            setTimings([]);
            toast.error(error.response?.data?.message || "Error fetching time slots.");
        }
    }, [date, activityId, locationId]);

    const handleChangeDate = (newDate: Dayjs | null) => {
        setDate(newDate);
        setTimings([]);
        setSelectedTimings([]);
    };

    const checkContinuity = (updatedTimings: Timing[]) => {
        for (let i = 0; i < updatedTimings.length - 1; i++) {
            const currentEnd = timeToMinutes(updatedTimings[i].endTime);
            const nextStart = timeToMinutes(updatedTimings[i + 1].startTime);
            if (nextStart !== currentEnd) return false;
        }
        return true;
    };

    const handleTimingChange = (startTime: string, endTime: string, isChecked: boolean) => {
        const newTiming = { startTime, endTime };
        setSelectedTimings((prevTimings) => {
            let updatedTimings = isChecked
                ? [...prevTimings, newTiming].sort((a, b) => timeToMinutes(a.startTime) - timeToMinutes(b.startTime))
                : prevTimings.filter(
                    (timing) => timing.startTime !== startTime || timing.endTime !== endTime
                );

            setError(checkContinuity(updatedTimings) ? null : "Please ensure time slots are continuous.");
            return updatedTimings;
        });
    };

    const handleRepeatBooking = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRepeatChecked(event.target.checked);
        setRepeatedDates(event.target.checked ? getNextThreeWeeks(date) : [date?.format("DD MMM") ?? ""]);
        // setRepeatedDates(event.target.checked ? getNextThreeWeeks(date) : [date?.format("DD MMM")].filter(val => val !== undefined));
        // setRepeatedDates(event.target.checked ? getNextThreeWeeks(date) : [date?.format("DD MMM")]);
    };

    const prepareBookingData = () => {
        const { earliest, latest } = findEarliestAndLatestTimes(selectedTimings);
        const activity = activityList.find((activity) => activity.id === Number(activityId));
        return {
            earliest,
            latest,
            selectedDates: repeatChecked ? repeatedDates : [date?.format("DD-MM-YYYY")],
            sportTypeName: activity?.typeName || "",
            courtName: activity?.name || "",
            selectedTimings,
            totalPrice: calculateTotalPrice(),
            activityId,
            locationId,
            repeat: repeatChecked,
            date,
        };
    };

    const handleNextBtn = () => {
        if (selectedTimings.length > 0 && !error) {
            const data = prepareBookingData();
            localStorage.setItem("bookingData", JSON.stringify(data));  // Save booking data to localStorage
            navigate(`/confirm-booking?locationId=${locationId}&activityId=${activityId}`);
        }
    };

    useEffect(() => {
        if (activityId && activityList.length > 0) {
            const selectedSport = activityList.find((sport) => sport.id === Number(activityId));
            setSinglePrice(selectedSport?.price || 0);
        }
    }, [activityId, activityList]);

    useEffect(() => {
        fetchInitialData();
    }, [locationId]);

    useEffect(() => {
        handleDateChange();
    }, [handleDateChange]);

    return (
        <div className="w-full relative bg-dark-blue overflow-hidden flex flex-col items-start justify-start pt-[3.25rem] px-[0rem] pb-[0rem] box-border gap-[1.75rem_0rem] tracking-[normal]">
            <Navbar />
            <div className="w-[71rem] lg:w-full mx-auto">
                <div className="w-7/12 lg:w-full">
                    <BookingHeader name={listing ? listing.name : ''} address={listing ? listing?.address : ''} />
                    <BookingSlider id={locationId ? locationId : ''} />
                    <form action="" className="flex flex-col gap-14">
                        <div className="sport-dropdown flex flex-col gap-5">
                            <p className="title-gradient label2 uppercase">Your Activity</p>
                            <div className="w-full flex lg:flex-col items-center justify-end gap-8">
                                <FormControl
                                    className="flex-1 lg:flex-auto flex justify-center"
                                    variant="outlined"
                                    sx={{
                                        border: "1px solid #fff",
                                        borderRadius: "16px",
                                    }}
                                >
                                    <Select
                                        disableUnderline
                                        displayEmpty
                                        value={activityId || '0'}
                                        onChange={handleChange}
                                        aria-label="Select activity"
                                        sx={{
                                            color: "#fff",
                                            "& .MuiSelect-icon":
                                            {
                                                color: "#FB709A",
                                                border: "1px solid #FB709A",
                                                borderRadius: "16px",
                                                marginRight: "8px",
                                            }
                                        }}
                                    >
                                        <MenuItem value="0" disabled>Select Court</MenuItem>
                                        {activityList.map((sport) => (
                                            <MenuItem key={sport.id} value={sport.id}>{sport.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <div className="flex items-center gap-4">
                                    <div className="rounded-2xl flex flex-row justify-start text-white bg-gravel1 h-12 items-center px-6">
                                        {singlePrice.toLocaleString()} /hr
                                    </div>
                                    <div className="flex justify-center items-center">
                                        {activityList.find((activity) => activity.id === Number(activityId))?.typeName ? (
                                            <img
                                                className="flex items-center"
                                                src={`/${activityList.find((activity) => activity.id === Number(activityId))?.typeName.toLowerCase()
                                                    }.png`}
                                                height={40}
                                                alt="Sport Type"
                                            />
                                        ) : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* DATE SELECTION */}
                        <div className="calendar flex flex-col gap-8">
                            <p className="label2 uppercase title-gradient">When would you like to play?</p>
                            <ThemeProvider
                                theme={createTheme({
                                    components: {
                                        MuiSvgIcon: { styleOverrides: { root: { color: "#ff69b4" } } },
                                    },
                                })}
                            >
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateCalendar
                                        value={date}
                                        onChange={handleChangeDate}
                                        minDate={today}
                                        maxDate={maxDate}
                                        views={["day"]}
                                    />
                                </LocalizationProvider>
                            </ThemeProvider>
                        </div>
                        {/* TIMING SELECTION */}
                        {timings.length ? (
                            <div className="flex flex-col gap-6">
                                <div className="select-timings gap-4 flex w-full overflow-x-scroll mt-4">
                                    {timings.map((time, id) => {
                                        // Check if this time slot was previously selected
                                        const isPreviouslySelected = selectedTimings.some(
                                            (selected) => selected.startTime === time.startAt && selected.endTime === time.endAt
                                        );

                                        return (
                                            <SelectTiming
                                                key={id}
                                                onTimingChange={handleTimingChange}
                                                startTime={time.startAt}
                                                endTime={time.endAt}
                                                initialChecked={isPreviouslySelected}  // Pass initial checked state
                                            />
                                        );
                                    })}
                                </div>
                                {error && (
                                    <div className="flex gap-2">
                                        <img src="alert_icon.svg" alt="Error icon" />
                                        <p className="label1 text-error">{error}</p>
                                    </div>
                                )}
                                <span className="text-white">
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={repeatChecked}
                                                sx={{ color: "#fff", "&.Mui-checked": { color: "#fff" } }}
                                                onChange={handleRepeatBooking}
                                            />
                                        }
                                        label="Repeat this booking for the next 4 weeks"
                                    />
                                </span>
                                <div className="flex items-center justify-start gap-2 text-white">
                                    {repeatChecked ? (
                                        <>
                                            <p className="text-white m-0">Selected Dates:</p>
                                            <h4 className="text-pink m-0">
                                                {repeatedDates.length > 0 ? repeatedDates.join(", ") : date?.format("DD MMM")}
                                            </h4>
                                        </>
                                    ) : (
                                        ""
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className="flex gap-2 text-white">
                                <p className="label1">No timings available for this day</p>
                            </div>
                        )}
                        {/* TOTAL COST DISPLAY */}
                        <div className="flex items-center gap-[25px] text-white">
                            <p className="m-0">Total Cost</p>
                            <h4 className="m-0">
                                {calculateTotalPrice().toLocaleString()} LKR
                            </h4>
                        </div>
                        {/* CONFIRM SELECTION */}
                        <div className="confirm flex flex-col gap-4">
                            <Button
                                btnText="Confirm Booking"
                                disabled={calculateTotalPrice() === 0 || !!error}
                                click={handleNextBtn}
                            />
                            <div className="w-[24rem] text-white">
                                The next step is to choose your payment method.
                            </div>
                        </div>
                    </form>
                </div>
            </div >
            <FooterHome />
        </div >
    );
};

export default SelectSport;

