import { FormEvent, FunctionComponent, useCallback, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthRequest from "../services/authRequest";
import { toast } from "react-toastify";
import Button from "../components/Button";

interface FormData {
  email: string;
}

const ForgotPassword: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [formData, setFormData] = useState<FormData>({
    email: "",
  });

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (formData.email === null || formData.email === "") {
      return;
    }
    try {
      setIsLoading(true);
      const response = await AuthRequest.get(
        `customer/forget-password?email=${formData.email}`
      );
      setIsEmailSent(true);
      console.log(response.data);
    } catch (error: any) {
      if (error.response) {
        toast.error(error.response.data.message);
      }
      console.log("error", error.response.data.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(e.target.value);
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const onVenuLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="w-full h-dvh relative bg-dark-blue overflow-hidden flex flex-row items-start justify-start pt-[0rem] pb-[0.012rem] px-[7.813rem] box-border gap-[9.375rem] tracking-[normal] text-left text-[0.875rem] text-pure-white font-app-body mq750:gap-[9.375rem_4.688rem] mq750:pl-[3.875rem] mq750:pr-[1.625rem] mq750:box-border mq450:gap-[9.375rem_2.313rem] mq450:pl-[1.25rem] mq450:box-border mq1100:flex-wrap">
      <div className="w-[28.188rem] h-full flex flex-col items-start justify-start pt-[3.381rem] px-[0rem] pb-[0rem] box-border min-w-[28.188rem] max-w-full mq750:pt-[2.188rem] mq750:box-border mq750:min-w-full mq1100:flex-1">
        <img
          className="w-[7.419rem] h-[2.138rem] relative shrink-0 [debug_commit:1cbd860] cursor-pointer"
          loading="lazy"
          alt=""
          src="/venu-logo.svg"
          onClick={onVenuLogoClick}
        />
        {!isEmailSent ? (
          <div className="self-stretch flex flex-col items-center justify-center h-full gap-[5.669rem] max-w-full mq450:gap-[2.813rem_5.669rem]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[1.688rem] shrink-0 [debug_commit:1cbd860] max-w-full">
              <div className="flex flex-col items-start justify-start pt-[0rem] px-[0.063rem] pb-[0.5rem]">
                <h2 className="title-gradient mb-0">Reset Password</h2>
                <p className="body">
                  Enter the email associated with your account and we’ll send an
                  email with instructions to reset your password.
                </p>
              </div>
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                className="self-stretch flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[0.438rem] box-border gap-[0.625rem] max-w-full text-pink"
              >
                <div className="self-stretch flex flex-col items-start justify-start gap-[1rem] max-w-full">
                  <input
                    type="email"
                    value={formData.email}
                    name="email"
                    onChange={handleChange}
                    placeholder="EMAIL ADDRESS"
                  />
                </div>
                <Button btnText={isLoading ? "loading" : "Send Instructions"} isLoading={isLoading} disabled={isLoading} />
                {/* <button
                  disabled={isLoading}
                  className="mt-4 cursor-pointer [border:none] py-[0.844rem] px-[1rem] bg-pink self-stretch rounded-81xl flex flex-row items-start justify-start box-border whitespace-nowrap max-w-full hover:bg-palevioletred-100"
                >
                  <div className="flex-1 relative text-[0.875rem] leading-[150%] uppercase font-semibold font-app-body text-gray text-center inline-block max-w-full">
                    {isLoading ? "loading" : "Send Instructions"}
                  </div>
                </button> */}
              </form>
            </div>
          </div>
        ) : (
          ""
        )}

        {/* Show After successful email API sent */}
        {isEmailSent ? (
          <div className="self-stretch flex flex-col items-center justify-center h-full gap-[5.669rem] max-w-full mq450:gap-[2.813rem_5.669rem]">
            <div className="self-stretch flex flex-col items-start justify-start gap-[1.688rem] shrink-0 [debug_commit:1cbd860] max-w-full">
              <div className="flex flex-col items-start justify-start pt-[0rem] px-[0.063rem] pb-[0.5rem]">
                <h2 className="title-gradient mb-0">Check Your Email</h2>
                <p className="body">
                  We have sent password recovery instructions to your email.
                </p>
                <p className="label1">
                  Did not receive an email? Check you spam filter or{" "}
                  <div className="text-pink underline cursor-pointer" onClick={() => { setIsEmailSent(false) }}>
                    try another email address
                  </div>
                </p>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <img
        className="h-full flex-1 relative max-w-full overflow-hidden object-contain min-w-[23.875rem] mq750:min-w-full"
        loading="lazy"
        alt=""
        src="/image-1888@2x.png"
      />
    </div>
  );
};

export default ForgotPassword;
