// AuthStatus.tsx
import React from 'react';

interface AuthStatusProps {
    children: (isLoggedIn: boolean, firstName?: string) => React.ReactNode;
}

const AuthStatus: React.FC<AuthStatusProps> = ({ children }) => {
    // Function to check if the user is logged in
    const isLoggedIn = () => {
        return sessionStorage.getItem('token') !== null;
    };

    // Function to get the user's first name from local storage
    const getFirstName = () => {
        const user = localStorage.getItem('firstName') || '{}';
        return user || '';
    };

    return <>{children(isLoggedIn(), getFirstName())}</>;
};

export default AuthStatus;
