import { useState, FormEvent, FunctionComponent, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AuthRequest from "../services/authRequest";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Button from "../components/Button";

interface FormData {
  firstName: string;
  lastName: string;
  contact: string;
  address: string;
  city: string;
  email: string;
  password: string;
  reEnterPassword: string;
}

const citiesInSriLanka: string[] = [
  "Agalawatta",
  "Agunukolapelassa",
  "Ahangama",
  "Ahungalla",
  "Akkareipathuwa",
  "Akmeemana",
  "Akurana",
  "Akuressa",
  "Alawwa",
  "Aluthgama",
  "Ambalangoda",
  "Ambanpola",
  "Ambepussa",
  "Ampara",
  "Anamaduwa",
  "Anguruwathota",
  "Anuradhapura",
  "Aralagamwila",
  "Aranayaka",
  "Athkadura",
  "Attanagalla",
  "Attidiya",
  "Aturugiriya",
  "Avissawella",
  "Ayagama",
  "Baddegama",
  "Badulla",
  "Baduraliya",
  "Balangoda",
  "Bandaragama",
  "Bandarawela",
  "Bangadeniya",
  "Batapola",
  "Battaluoya",
  "Battaramulla",
  "Batticaloa",
  "Beliatta",
  "Benthota",
  "Beruwala",
  "Bibila",
  "Bingiriya",
  "Bogawantalawa",
  "Boralesgamuwa",
  "Bulathkohupitiya",
  "Bulathsinghala",
  "Buthala",
  "Chillaw",
  "Colombo 01 - ( Fort )",
  "Colombo 02 - ( Union Place )",
  "Colombo 03 - ( Kollupitiya )",
  "Colombo 04 - ( Bambalapitiya )",
  "Colombo 05 - ( Havelock / Kirulapana )",
  "Colombo 06 - ( Wellawatta )",
  "Colombo 07 - ( Cinnamon Gardens )",
  "Colombo 08 - ( Borella )",
  "Colombo 09 - ( Dematagoda )",
  "Colombo 10 - ( Maradana )",
  "Colombo 11 - ( Pettah )",
  "Colombo 12 - ( Hultsdorf )",
  "Colombo 13 - ( Kotahena )",
  "Colombo 14 - ( Grandpass )",
  "Colombo 15 - ( Mattakuliya )",
  "Dambulla",
  "Dankotuwa",
  "Dehiathakandiya",
  "Dehiwala",
  "Deiyandara",
  "Delgoda",
  "Delkanda",
  "Deltota",
  "Deniyaya",
  "Deraniyagala",
  "Dharga Town",
  "Digana",
  "Diyabeduma",
  "Dodanduwa",
  "Dompe",
  "Dunagaha",
  "Dvulapitiya",
  "Eheliyagoda",
  "Ekala",
  "Elahera",
  "Elpitiya",
  "Embilipitiya",
  "Eppawala",
  "Galaha",
  "Galewela",
  "Galgamuwa",
  "Galhinna",
  "Galle",
  "Galnewa",
  "Gampaha",
  "Gampola",
  "Ganemulla",
  "Gangodawila",
  "Gatahetta",
  "Gelioya",
  "Ginigathhena",
  "Ginthota",
  "Giradurukotte",
  "Giriulla",
  "Godagama",
  "Godakawela",
  "Gonapeenuwala",
  "Gothatuwa",
  "Habarana",
  "Hakmana",
  "Hambanthota",
  "Hanguranketha",
  "Hanwella",
  "Hatton",
  "Henegama",
  "Hettipola Matale",
  "Hikkaduwa",
  "Hingurakgoda",
  "Hokandara",
  "Homagama",
  "Horana",
  "Horawala",
  "Horowpothana",
  "Ibbagamuwa",
  "Idangoda",
  "Imaduwa",
  "Ingiriya",
  "Ja-Ela",
  "Jaffna",
  "Kadawatha",
  "Kadugannawa",
  "Kaduwala",
  "kahawatta",
  "Kalawana",
  "Kalmunai",
  "Kalpitiya",
  "Kalubowila",
  "Kaluthara",
  "Kamburupitiya",
  "Kandana",
  "Kandy",
  "Kanthale",
  "Karagampitiya",
  "Karuwalagaswewa",
  "Katana",
  "Kataragama",
  "Katubedda",
  "Katugasthota",
  "Katunayaka",
  "Katuneriya",
  "Katupotha",
  "Kebithigollewa",
  "Kegalle",
  "Kekirawa",
  "Kelaniya",
  "Kesbewa",
  "Kilinochchiya",
  "Kirama",
  "Kiribathgoda",
  "Kiriella",
  "Kirillawala",
  "Kirindiwela",
  "Kochchikade",
  "Kohuwala",
  "Kolonna",
  "Kolonnawa",
  "Kosgama",
  "Koswatha",
  "Kotadeniyawa",
  "Kotikawatta",
  "Kotiyakumbura",
  "Kotmale",
  "Kottawa",
  "Kotte",
  "Kuliyapitiya",
  "Kumbukgate",
  "Kundasale",
  "Kurunegala",
  "Kuruwita",
  "Laggala Pallegama",
  "Lunugala",
  "Lunuwila",
  "Madagalla",
  "Madampe",
  "Madawala",
  "Madurankuliya",
  "Maha oya",
  "Mahabage",
  "Maharagama",
  "Mahawewa",
  "Mahiyanganaya",
  "Maho",
  "Makandura",
  "Makandura Matara",
  "Makola",
  "Malambe",
  "Mallawapitiya",
  "Malwana",
  "Mankulum",
  "Mannar",
  "Mapalagama",
  "Maradankadawala",
  "Marandagahamula",
  "Marawila",
  "Maskeliya",
  "Matale",
  "Matara",
  "Mathugama",
  "Mattegoda",
  "Mawanella",
  "Medamahanuwara",
  "Medawachiya",
  "Medirigiriya",
  "Meegoda",
  "Melsiripura",
  "Middeniya",
  "Minuwangoda",
  "Mirigama - ( N )",
  "Mirihana",
  "Mirissa",
  "Monaragala",
  "Moragala",
  "Moragollagama",
  "Moratuwa",
  "Morawaka",
  "Mount Lavinia",
  "Mullathiv",
  "Mulleriyawa",
  "Mundala",
  "Mutur",
  "Narahempita",
  "Narammala",
  "Nattandiya",
  "Naula",
  "Nawagathegama",
  "Nawala",
  "Nawalapitiya",
  "Neboda",
  "Negombo",
  "Nelumdeniya",
  "Neluwa",
  "Nikaweratiya",
  "Nittambuwa",
  "Nivithigala",
  "Nugegoda",
  "Nuwara Eliya",
  "Padaviya",
  "Padiyapelalla",
  "Padiyatalawa",
  "Padukka",
  "Palavee",
  "Pamunugama",
  "Panadura",
  "Panamure",
  "Panchikawatte",
  "Pannala",
  "Pannipitiya",
  "Panwila",
  "Pasgoda",
  "Passara",
  "Pasyala",
  "Payagala",
  "Pelawatta",
  "Pelawatta Mathugama",
  "Peliyagoda",
  "Pelmadulla",
  "Peradeniya",
  "Pilimathalawa",
  "Piliyandala",
  "Pitabeddara",
  "Pitigala",
  "Polgahawela",
  "Polgasovita",
  "Polgasowita",
  "Polhengoda",
  "Polonnaruwa",
  "Polpithigama",
  "Pothuvil",
  "Pugoda",
  "Pussallawa",
  "Puttalam",
  "Radavana",
  "Raddolugama",
  "Ragama",
  "Rajagiriya",
  "Rakwana",
  "Rambukkana",
  "Ranna",
  "Rathnapura",
  "Ratmalana",
  "Rikillagskada",
  "Ruwanwella",
  "Seeduwa",
  "Serunuwara",
  "Sigiriya",
  "Siyabalaanduwa",
  "Sooriyawewa",
  "Thalawathugoda",
  "Thambuthegama",
  "Thanamalwila",
  "Thangalle",
  "Thawalama",
  "Tissamaharamaya",
  "Trincomalee",
  "Udawalawa",
  "Ududumbara",
  "Udugama",
  "Unawatuna",
  "Urapola",
  "Urubokka",
  "Vavuniya",
  "Vellankulam",
  "Veyangoda",
  "Wadduwa",
  "Waduramba",
  "Walapane",
  "Walasmulla",
  "Warakapola",
  "Wariyapola",
  "Waskaduwa",
  "Wattala",
  "Weerawila",
  "Weligama",
  "Welimada",
  "Welisara",
  "Weliveriya",
  "Wellampitiya",
  "Wellawaya",
  "Wennappuwa",
  "Wijerama",
  "Yakkala",
  "Yala",
  "Yatiyanthota",
];

const PlayerSignUp: FunctionComponent = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1); // Track the current step
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isRePasswordVisible, setIsRePasswordVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [path, setPath] = useState("");
  const [formData, setFormData] = useState<FormData>({
    firstName: "",
    lastName: "",
    contact: "",
    address: "",
    city: "",
    email: "",
    password: "",
    reEnterPassword: "",
  });

  useEffect(() => {
    let path = localStorage.getItem("resumePath");
    setPath(path ? path : "");
  }, []);

  const validateStep1 = (): boolean => {
    const { firstName, lastName, contact, address, city } = formData;
    if (!firstName || !lastName || !contact || !address || !city) {
      toast.error("All fields are required.");
      return false;
    }

    const phoneNumberRegex = /^\d{9,10}$/;
    if (!phoneNumberRegex.test(contact)) {
      toast.error("Phone number must be a valid 9-10 digit number.");
      return false;
    }

    const nameRegex = /^[a-zA-Z\s]+$/;
    if (!nameRegex.test(firstName) || !nameRegex.test(lastName)) {
      toast.error("First and last names should not contain numbers.");
      return false;
    }
    return true;
  };

  const validateStep2 = (): boolean => {
    const { email, password, reEnterPassword } = formData;
    if (!email || !password || !reEnterPassword) {
      toast.error("All fields are required.");
      return false;
    }

    if (password.length < 8) {
      toast.error("Password must be at least 8 characters.");
      return false;
    }

    if (password !== reEnterPassword) {
      toast.error("Passwords do not match.");
      return false;
    }

    if (!/^\S+$/.test(password)) {
      toast.error("Password must not contain spaces.");
      return false;
    }

    return true;
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (step === 1) {
      if (validateStep1()) {
        setStep(2); // Move to step 2
      }
    } else if (step === 2) {
      if (validateStep2()) {
        try {
          setIsLoading(true);
          const response = await AuthRequest.post(
            "/Auth/customer/register",
            formData
          );

          const { token, refreshToken, details } = response.data.data;
          sessionStorage.setItem("token", token);
          sessionStorage.setItem("refreshToken", refreshToken);

          localStorage.setItem("email", details.email);
          localStorage.setItem("firstName", details.firstName);
          localStorage.setItem("lastName", details.lastName);
          localStorage.setItem("contact", details.contact);
          localStorage.setItem("address", details?.address);
          localStorage.setItem("city", details?.city);
          localStorage.setItem("id", details.id.toString());

          toast.success(response.data.message);
          // Redirect the user to the homepage
          let navigationPath = path ? "/" + path : "/";
          navigate(navigationPath);
        } catch (error: any) {
          toast.error(error.response?.data.message || "An error occurred.");
        } finally {
          setIsLoading(false);
        }
      }
    }
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const togglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);
  const toggleRePasswordVisibility = () =>
    setIsRePasswordVisible(!isRePasswordVisible);

  return (
    <div className="bg-dark-blue">
      <div className="container flex flex-col min-h-dvh pt-14">
        <div className="w-full flex">
          <div className="w-1/12 lg:hidden"></div>
          <div className="w-11/12 lg:w-full">
            <img
              className="w-[7.419rem] h-[2.138rem] cursor-pointer"
              alt=""
              src="/venu-logo.svg"
              onClick={() => navigate("/")}
            />
          </div>
        </div>
        <div className="w-full flex flex-1 items-center">
          <div className="w-1/12 lg:hidden"></div>
          <div className="w-5/12 lg:w-full h-full">
            <div className="flex flex-col gap-[1.75rem] w-full">
              <div className="flex flex-col items-start">
                {step == 2 ? (
                  <img
                    src="/ggchevrondowno1@2x.png"
                    className="cursor-pointer"
                    onClick={() => setStep(1)}
                    width={"24px"}
                    alt=""
                  />
                ) : (
                  ""
                )}
                <h2 className="title-gradient">
                  {step === 1
                    ? "Step 1: Basic Information"
                    : "Step 2: Account Details"}
                </h2>
              </div>
              <form
                onSubmit={handleSubmit}
                className="flex flex-col gap-[1.375rem]"
              >
                {step === 1 && (
                  <>
                    <input
                      type="text"
                      value={formData.firstName}
                      name="firstName"
                      onChange={handleChange}
                      placeholder="First Name"
                    />
                    <input
                      type="text"
                      value={formData.lastName}
                      name="lastName"
                      onChange={handleChange}
                      placeholder="Last Name"
                    />
                    <input
                      type="text"
                      value={formData.address}
                      name="address"
                      onChange={handleChange}
                      placeholder="Address"
                    />
                    <select
                      value={formData.city}
                      name="city"
                      onChange={handleChange}
                    >
                      <option value="" disabled>
                        Select your city
                      </option>
                      {citiesInSriLanka.map((city) => (
                        <option key={city} value={city}>
                          {city}
                        </option>
                      ))}
                    </select>
                    <input
                      type="tel"
                      value={formData.contact}
                      name="contact"
                      onChange={handleChange}
                      placeholder="Contact"
                    />
                  </>
                )}

                {step === 2 && (
                  <>
                    <input
                      type="email"
                      value={formData.email}
                      name="email"
                      onChange={handleChange}
                      placeholder="Email"
                    />
                    <div className="relative flex w-full justify-between">
                      <input
                        type={isPasswordVisible ? "text" : "password"}
                        value={formData.password}
                        name="password"
                        onChange={handleChange}
                        placeholder="Password"
                      />
                      <span
                        onClick={togglePasswordVisibility}
                        className="text-pink"
                      >
                        {isPasswordVisible ? (
                          <VisibilityOff
                            color="inherit"
                            className="absolute right-5 top-[16px]"
                          />
                        ) : (
                          <Visibility
                            color="inherit"
                            className="absolute right-5 top-[16px]"
                          />
                        )}
                      </span>
                    </div>
                    <div className="relative flex w-full justify-between">
                      <input
                        type={isRePasswordVisible ? "text" : "password"}
                        value={formData.reEnterPassword}
                        name="reEnterPassword"
                        onChange={handleChange}
                        placeholder="Re-enter Password"
                      />
                      <span
                        onClick={toggleRePasswordVisibility}
                        className="text-pink"
                      >
                        {isRePasswordVisible ? (
                          <VisibilityOff
                            color="inherit"
                            className="absolute right-5 top-[16px]"
                          />
                        ) : (
                          <Visibility
                            color="inherit"
                            className="absolute right-5 top-[16px]"
                          />
                        )}
                      </span>
                    </div>
                  </>
                )}
                <div className="flex flex-col text-center">
                  <Button
                    isLoading={isLoading}
                    btnText={step === 1 ? "Next" : "Sign Up"}
                  />
                  <p className="body text-white">
                    Already have an account? &nbsp;
                    <a href="/login" className="text-pink">
                      Login
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
          <div className="w-1/12 lg:hidden"></div>
          <div className="w-5/12 lg:hidden h-full flex self-end">
            <img src="/image-1888@2x.png" className="w-full" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerSignUp;
