import {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from "react";
import Connector from "./Connector";
import dayjs, { Dayjs } from "dayjs";
import {
  Checkbox,
  FormControlLabel,
  ThemeProvider,
  createTheme,
} from "@mui/material";
import { DateCalendar, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { toast } from "react-toastify";
import Button from "./Button";
import AuthRequest from "../services/authRequest";
import { useNavigate } from "react-router-dom";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import SelectTiming from "./SelectTiming";
import {
  ActivityListProps,
  TimeSlot,
  FormattedTimeSlot,
  SportSelectionProps,
} from "../types";

dayjs.extend(utc);
dayjs.extend(timezone);

// Helper function to convert time strings to minutes since start of day
const timeToMinutes = (time: string) => {
  const [hours, minutes] = time.split(":").map(Number);
  return hours * 60 + minutes;
};

// Helper function to extract and format time from ISO strings
const formatTimeSlots = (timeSlots: TimeSlot[]): FormattedTimeSlot[] => {
  return timeSlots.map((slot) => ({
    startAt: slot.startAt.substring(11, 16), // HH:mm format
    endAt: slot.endAt.substring(11, 16),
  }));
};

// Helper function to find the earliest start time and latest end time
const findEarliestAndLatestTimes = (selectedTimes: string[][]) => {
  let startAt = selectedTimes[0][0];
  let endAt = selectedTimes[0][1];

  selectedTimes.forEach((time) => {
    if (time[0] < startAt) startAt = time[0];
    if (time[1] > endAt) endAt = time[1];
  });

  return { startAt, endAt };
};

const SportSelection: FunctionComponent<SportSelectionProps> = ({
  locationId,
  courtId,
}) => {
  const navigate = useNavigate();
  const today = dayjs();
  const maxDate = dayjs().add(2, "month");

  const [activityList, setActivityList] = useState<ActivityListProps[]>([]);
  const [date, setDate] = useState<Dayjs | null>(() => {
    try {
      const savedBooking = localStorage.getItem('booking');
      if (savedBooking) {
        const parsedBooking = JSON.parse(savedBooking);

        // Check if we have selectedDates array and it's not empty
        if (parsedBooking.selectedDates && parsedBooking.selectedDates.length > 0) {
          // Take first date from the array
          const firstDate = parsedBooking.selectedDates[0];
          // Parse the date in DD-MM-YYYY format
          return dayjs(firstDate, 'DD-MM-YYYY');
        }
      }
    } catch (error) {
      console.error('Error reading date from localStorage:', error);
    }
    return today;
  });
  const [activityId, setActivityId] = useState<string>(courtId);
  const [timings, setTimings] = useState<FormattedTimeSlot[]>([]);
  const [selectedTimes, setSelectedTimes] = useState<string[][]>([]);
  const [singlePrice, setSinglePrice] = useState<number>(0);
  const [checked, setChecked] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [repeatedDates, setRepeatedDates] = useState<string[]>([]);
  const [isEditing, setIsEditing] = useState(false);

  
  const totalPrice = useMemo(() => {
    // If we're editing (user has changed time or date), calculate new price
    if (isEditing) {
      return selectedTimes.length * singlePrice;
    }

    // If not editing, check localStorage first
    const savedBooking = localStorage.getItem('booking');
    if (savedBooking) {
      const parsedBooking = JSON.parse(savedBooking);
      return parsedBooking.totalPrice;
    }

    // Default calculation if no saved booking
    return selectedTimes.length * singlePrice;
  }, [selectedTimes, singlePrice, isEditing]);

  // Fetch available activities by location
  const getLocationBySearch = useCallback(async () => {
    try {
      const response = await AuthRequest.get(
        `Location/customer/activities-by-location?id=${locationId}`
      );
      setActivityList(response.data.data);
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  }, [locationId]);

  // Fetch available times for selected date and activity
  const handleDateChange = useCallback(async () => {
    setIsEditing(true);
    if (!date || activityId === "0") return;
    const formattedDate = date.format("YYYY-MM-DD");

    try {
      const response = await AuthRequest.post(
        "Location/customer/available-times",
        {
          locationId,
          activityId,
          date: formattedDate,
        }
      );
      const formattedTimeSlots = formatTimeSlots(response.data.data);
      setTimings(formattedTimeSlots);
    } catch (error: any) {
      setTimings([]);
      toast.error(
        error.response?.data?.message || "Error fetching time slots."
      );
    }
  }, [date, activityId, locationId]);

  const handleChangeDate = (newDate: Dayjs | null) => {
    setDate(newDate);  // Set the new selected date
    setTimings([]); // Reset the timings array
    setSelectedTimes([]); // Reset the selected timings array as well
    // setSinglePrice(0);
    console.log('Price:' + totalPrice);
    console.log('Times' + selectedTimes);

    // Always update repeatedDates with the selected date
    // if (newDate) {
    //   const nextDates = checked ? getNextThreeWeeks(newDate) : [newDate.format('DD MMM')];
    //   setRepeatedDates(nextDates);
    // }
  };

  // Handle time slot selection and validation
  const handleTimeSelection = (newTimes: string[]) => {
    setIsEditing(true)
    setSelectedTimes((prevTimes) => {
      const newStart = timeToMinutes(newTimes[0]);
      const newEnd = timeToMinutes(newTimes[1]);

      const index = prevTimes.findIndex(
        (times) => times[0] === newTimes[0] && times[1] === newTimes[1]
      );
      if (index >= 0) {
        setError(null);
        return prevTimes.filter((_, i) => i !== index);
      }

      if (prevTimes.length > 0) {
        const isConsecutive = prevTimes.some((times) => {
          const start = timeToMinutes(times[0]);
          const end = timeToMinutes(times[1]);
          return newEnd === start || newStart === end;
        });

        if (!isConsecutive) {
          setError("You can only select consecutive times.");
          return prevTimes;
        }
      }

      setError(null);
      return [...prevTimes, newTimes];
    });
  };

  const handleRepeatBooking = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    setIsEditing(true)
    if (event.target.checked && date) {
      const nextDates = getNextThreeWeeks(date);
      setRepeatedDates(nextDates);
    } else if (date) {
      setRepeatedDates([date.format("DD MMM")]);
    } else {
      setRepeatedDates([]);
    }
  };

  // Function to calculate the next 3 weeks of the selected date
  const getNextThreeWeeks = (selectedDate: Dayjs | null) => {
    if (!selectedDate) return []; // Handle the case where date is null

    return [
      selectedDate,
      selectedDate.add(1, "week"),
      selectedDate.add(2, "week"),
      selectedDate.add(3, "week"),
    ].map((date) => date.format("DD MMM"));
  };

  const handleNextBtn = () => {
    if (!selectedTimes.length) return;
    const { startAt, endAt } = findEarliestAndLatestTimes(selectedTimes);
    const selectedDate = date?.toISOString();
    const activity = activityList.find(
      (activity) => activity.id === Number(activityId)
    );

    const data = {
      startAt,
      endAt,
      selectedDates: checked ? repeatedDates : [date?.format("DD-MM-YYYY")], // Store array of dates
      sportTypeName: activity?.typeName || "",
      courtName: activity?.name || "",
      selectedTimes,
      totalPrice: checked ? totalPrice * 4 : totalPrice,
      activityId,
      locationId,
      repeat: checked,
      date: date,
    };

    localStorage.setItem("booking", JSON.stringify(data));
    navigate(`/confirm-booking?locationId=${locationId}`);
  };

  useEffect(() => {
    getLocationBySearch();
  }, [getLocationBySearch]);

  useEffect(() => {
    handleDateChange();
  }, [handleDateChange]);

  return (
    <>
      <div className="w-full flex flex-col lg:px-5">
        <Connector
          onPriceChange={setSinglePrice}
          onCourtChange={setActivityId}
          selectSport="Your Activity"
          sportNames={activityList}
          courtId={activityId}
        />
        <div className="w-full mt-14">
          <p className="label2 uppercase title-gradient mb-8">
            When would you like to play?
          </p>
          <ThemeProvider
            theme={createTheme({
              components: {
                MuiSvgIcon: { styleOverrides: { root: { color: "#ff69b4" } } },
              },
            })}
          >
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateCalendar
                value={date}
                onChange={handleChangeDate}
                minDate={today}
                maxDate={maxDate}
                views={['day']}
              />
            </LocalizationProvider>
          </ThemeProvider>
          {/* <div className="w-full mt-14 text-white">
            {timings.length ? (
              <div className="select-timings gap-4 flex w-full overflow-x-scroll mt-4">
                {timings.map((time) => (
                  <SelectTiming
                    key={time.startAt}
                    onTimeSelect={handleTimeSelection}
                    startTime={time.startAt}
                    endTime={time.endAt}
                  />
                ))}
              </div>
            ) : (
              <div className="flex gap-2">
                <p className="label1">No timings available for this day</p>
              </div>
            )}
            {error &&
              <div className="flex gap-2 mt-4">
                <img src="alert_icon.svg" alt="" />
                <p className="label1 text-error">{error}</p>
              </div>
            }
          </div> */}
        </div>
        <span className="text-white mt-6">
          <FormControlLabel
            color="#fff"
            control={
              <Checkbox
                checked={checked}
                sx={{ color: "#fff", "&.Mui-checked": { color: "#fff" } }}
                onChange={handleRepeatBooking}
              />
            }
            label="Repeat this booking for the next 4 weeks"
          />
          <div className="flex items-center justify-start gap-2 text-white mt-6 mb-14">
            {
              checked ? (
                <>
                  <p className="text-white m-0">Selected Dates:</p>
                  <h4 className="text-pink m-0">
                    {repeatedDates.length > 0 ? repeatedDates.join(", ") : date?.format('DD MMM')}
                  </h4>
                </>
              ) : (
                ''
              )
            }
            {/* <h4 className="text-white m-0">
              {repeatedDates.length > 0 ? <p>{repeatedDates}</p> : date ? date.format('DD MMM') : "No date selected"}
            </h4> */}
          </div>
        </span>
        <div className="flex flex-col gap-6 mb-14">
          <div className="flex items-center gap-[25px] text-white">
            <p className="m-0">Total Cost</p>
            <h4 className="m-0">
              {(checked ? totalPrice * 4 : totalPrice).toLocaleString()} LKR
            </h4>
          </div>
        </div>
        <Button
          btnText="Confirm Booking"
          disabled={totalPrice === 0}
          click={handleNextBtn}
        />
        <div className="w-[24rem] mt-6 text-white">
          The next step is to choose your payment method.
        </div>
      </div>
    </>
  );
};

export default SportSelection;