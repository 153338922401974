import {
  FormEvent,
  FunctionComponent,
  useCallback,
  useState,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import AuthRequest from "../services/authRequest";
import { toast } from "react-toastify";
import { StringLiteralType } from "typescript";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Button from "../components/Button";
interface FormData {
  email: string;
}

const NewPassword: FunctionComponent = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [token, setToken] = useState("");
  const [password, setPassword] = useState("");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [reEnterPassword, setReEnterPassword] = useState("");
  const [isReEnterPasswordVisible, setIsReEnterPasswordVisible] =
    useState(false);

  const handleIsPasswordVisible = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const handleIsReEnterPasswordVisible = () => {
    setIsReEnterPasswordVisible(!isReEnterPasswordVisible);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    console.log(token);
    if (!validateForm(password, reEnterPassword)) {
      return;
    }
    try {
      setIsLoading(true);
      const response = await AuthRequest.post("/Customer/change-password", {
        token: token,
        password: password,
      });

      // Use React Toastify to show a success message
      toast.success(response.data.message);

      // Redirect the user to the homepage
      navigate("/");
    } catch (error:any) {
      if (error.response) {
        toast.error(error.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  function validateForm(password: string, reEnterPassword: string): boolean {
    if (!password || !reEnterPassword) {
      toast.error("All fields are required.");
      return false;
    }
    if (password.length < 8) {
      toast.error("Password must be at least 8 characters.");
      return false;
    }

    if (password !== reEnterPassword) {
      toast.error("Passwords do not match.");
      return false;
    }
    // Add more validation checks as needed
    return true;
  }

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token") as string;
    if (token !== "" && token != undefined && token !== null) {
      setToken(token);
    }
  }, [location]);

  const onVenuLogoClick = useCallback(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="w-full h-dvh relative bg-dark-blue overflow-hidden flex flex-row items-start justify-start pt-[0rem] pb-[0.012rem] px-[7.813rem] box-border gap-[9.375rem] tracking-[normal] text-left text-[0.875rem] text-pure-white font-app-body mq750:gap-[9.375rem_4.688rem] mq750:pl-[3.875rem] mq750:pr-[1.625rem] mq750:box-border mq450:gap-[9.375rem_2.313rem] mq450:pl-[1.25rem] mq450:box-border mq1100:flex-wrap">
      <div className="w-[28.188rem] h-full flex flex-col items-start justify-start pt-[3.381rem] px-[0rem] pb-[0rem] box-border min-w-[28.188rem] max-w-full mq750:pt-[2.188rem] mq750:box-border mq750:min-w-full mq1100:flex-1">
        <img
          className="w-[7.419rem] h-[2.138rem] relative shrink-0 [debug_commit:1cbd860] cursor-pointer"
          loading="lazy"
          alt=""
          src="/venu-logo.svg"
          onClick={onVenuLogoClick}
        />
        <div className="self-stretch flex flex-col items-center justify-center h-full gap-[5.669rem] max-w-full mq450:gap-[2.813rem_5.669rem]">
          <div className="self-stretch flex flex-col items-start justify-start gap-[1.688rem] shrink-0 [debug_commit:1cbd860] max-w-full">
            <div className="flex flex-col items-start justify-start pt-[0rem] px-[0.063rem] pb-[0.5rem]">
              <h2 className="title-gradient mb-0">Create New Password</h2>
              <p className="body">
                Your new password must be different to previously used
                password.
              </p>
            </div>
            <form
              autoComplete="off"
              onSubmit={handleSubmit}
              className="self-stretch flex flex-col items-end justify-start pt-[0rem] px-[0rem] pb-[0.438rem] box-border gap-[0.625rem] max-w-full text-pink"
            >
              <div className="self-stretch flex flex-col items-start justify-start gap-[1rem] max-w-full">
                <div className="relative self-stretch flex w-full justify-between">
                  <input
                    type={isPasswordVisible ? "text" : "password"}
                    value={password}
                    name="password"
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="PASSWORD"
                  />
                  <span onClick={handleIsPasswordVisible} className="text-pink">
                    {isPasswordVisible ? (
                      <VisibilityOff
                        color="inherit"
                        className="absolute right-5 top-[20px]"
                      />
                    ) : (
                      <Visibility
                        color="inherit"
                        className="absolute right-5 top-[20px]"
                      />
                    )}
                  </span>
                </div>

                <div className="relative self-stretch flex w-full justify-between">
                  <input
                    type={isReEnterPasswordVisible ? "text" : "password"}
                    value={reEnterPassword}
                    name="password"
                    onChange={(e) => setReEnterPassword(e.target.value)}
                    placeholder="CONFIRM PASSWORD"
                  />
                  <span onClick={handleIsReEnterPasswordVisible} className="text-pink">
                    {isReEnterPasswordVisible ? (
                      <VisibilityOff
                        color="inherit"
                        className="absolute right-5 top-[20px]"
                      />
                    ) : (
                      <Visibility
                        color="inherit"
                        className="absolute right-5 top-[20px]"
                      />
                    )}
                  </span>
                </div>
              </div>
              <Button btnText={isLoading ? "loading" : "Reset Password"} disabled={isLoading} isLoading={isLoading}  />
            </form>
          </div>
        </div>
      </div>
      <img
        className="h-full flex-1 relative max-w-full overflow-hidden object-contain min-w-[23.875rem] mq750:min-w-full"
        loading="lazy"
        alt=""
        src="/image-1888@2x.png"
      />
    </div>
  );
};

export default NewPassword;
