import { FunctionComponent, useEffect, useState } from "react";
import BookingHeader from "../components/BookingHeader";
import BookingSlider from "../components/BookingSlider";
import ConfirmBookingComp from "../components/ConfirmBookingComp";
import FooterHome from "../components/FooterHome";
import Navbar from "../components/Navbar";
import AuthRequest from "../services/authRequest";
import { Link, useSearchParams } from "react-router-dom";
import Button from "../components/Button";

interface BookingLocationProps {
  name: string,
  address: string,
  contact: string,
}

const ConfirmBooking: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const [listing, setListing] = useState<BookingLocationProps>()
  const [searchVal, setSearchVal] = useState(searchParams.get('locationId'));

  const getLocationBySearch = async () => {
    try {
      const response = await AuthRequest.get(`Location/customer/location-id?id=${searchVal}`)
      console.log(response.data);
      setListing(response.data.data);
    } catch (error) {
      console.log('error', error);
    }
  }
  useEffect(() => {
    getLocationBySearch();
  }, [searchVal])

  return (
    <div className="w-full relative bg-dark-blue overflow-hidden flex flex-col items-start justify-start pt-[3.25rem] px-[0rem] pb-[0rem] box-border gap-[1.75rem_0rem] tracking-[normal]">
      <Navbar />
      <div className="w-[71rem] lg:w-full mx-auto">
        <div className="w-9/12 lg:w-full px-5">
          <h1 className="title-gradient">Complete Booking</h1>
          {/* <BookingHeader name={listing ? listing.name : ''} address={listing ? listing?.address : ''} /> */}
          {/* <BookingSlider /> */}
          <section className="self-stretch flex flex-row items-start justify-center">
            <ConfirmBookingComp name={listing ? listing.name : ''} address={listing ? listing?.address : ''} />
          </section>
        </div>
      </div>
      <FooterHome />
    </div>
  );
};

export default ConfirmBooking;
