import { FunctionComponent } from "react";
import Navbar from "../components/Navbar";
import FooterHome from "../components/FooterHome";

const Refund: FunctionComponent = () => {
    return (
        <section className="w-full flex flex-col items-center relative bg-dark-blue pt-[3.25rem]">
            <Navbar />
            <div className="w-[71rem] lg:w-full flex flex-row mx-auto relative h-full items-center lg:px-[1.25rem]">
                <h2 className="title-gradient">Refund Policy for Venu Technologies</h2>
            </div>
            <div className="content w-[71rem] lg:w-full text-white lg:px-[1.25rem]">
                <p className="body">We aim to ensure your satisfaction with our platform and the venues you book through Venu Technologies. Below is our refund policy for bookings:</p>
                
                <h4>1. Refund Eligibility</h4>
                <ul>
                    <li className="body">If the customer makes a mistake during the booking process, unfortunately, no refund will be issued at this time.</li>
                </ul>

                <h4>2. Refund Process</h4>
                <ul>
                    <li className="body">For Cancellations by the Customer: If you cancel within the venue’s allowed timeframe, the refund will be processed within 5-7 business days via your original payment method.</li>
                </ul>

                <h4>3. Non-Refundable Fees</h4>
                <ul>
                    <li className="body">There are no refund processing or administrative fees. All refunds will be processed in full.</li>
                </ul>

                <h4>4. How to Request a Refund</h4>
                <p className="body">For refunds, please contact Burhan Shakir, Chief Sales Executive, at 077-462-2736 with your booking details. Refund requests must be made within 48 hours of the scheduled booking time.</p>
            </div>
            <FooterHome />
        </section>
    )
}

export default Refund;