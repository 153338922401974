import axios from "axios";
import { API_URL } from '../config';

const baseURL = `${API_URL}`;

const getToken = () => {
  if (typeof window !== "undefined") {
    return sessionStorage.getItem("token");
  }
  return null;
};

const UserRequest = axios.create({
  baseURL,
  timeout: 10000,
});

// Function to handle token refresh
const refreshToken = async () => {
  try {
    // Creating a separate axios instance for refresh token request
    const refreshRequest = axios.create({
      baseURL,
      timeout: 20000,
    });
    const response = await refreshRequest.post("/Auth/customer/refresh-token", {
      token: sessionStorage.getItem("refreshToken"),
    });
    sessionStorage.setItem("token", response.data.data.token);
    sessionStorage.setItem("refreshToken", response.data.data.refreshToken);

    // Save the email, firstName, and id in local storage
    localStorage.setItem("email", response.data.data.details.email);
    localStorage.setItem("firstName", response.data.data.details.firstName);
    localStorage.setItem("lastName", response.data.data.details.lastName);
    localStorage.setItem("contact", response.data.data.details.contact);
    localStorage.setItem("address", response.data.data.details?.address);
    localStorage.setItem("id", response.data.data.details.id.toString());
    return response.data.data.token;
  } catch (error) {
    throw error;
  }
};

UserRequest.interceptors.request.use(
  (config) => {
    const token = getToken();
    delete config.headers["Authorization"];
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
UserRequest.interceptors.response.use(
  (response) => {
    // If the response is successful, just return it
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      try {
        // Attempt to refresh the token
        const newAccessToken = await refreshToken();

        // Clone the original request
        const originalRequest = error.config;
        originalRequest.headers["Authorization"] = `Bearer ${newAccessToken}`;

        // Execute the modified request with the userRequest instance
        return UserRequest(originalRequest);
      } catch (refreshError) {
        // If token refresh fails, redirect to login
        redirectToLogin();
      }
    }
    // else if (
    //   error.code === "ERR_NETWORK" ||
    //   error.message === "Network Error" ||
    //   !navigator.onLine
    // ) {
    //   // Handle network errors, including being offline
    //   redirectToLogin();
    // }

    // Propagate the error for further handling
    return Promise.reject(error);
  }
);

function redirectToLogin() {
  if (typeof window !== "undefined") {
    sessionStorage.clear();
    localStorage.clear();
    window.location.replace("/login");
  }
}

export default UserRequest;
