import { FunctionComponent, useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';
import IndividualListing from "../components/IndividualListing";
import MainFrameContainer from "../components/MainFrameContainer";
import Navbar from "../components/Navbar";
import AuthRequest from "../services/authRequest";
import { CircularProgress, TextField } from "@mui/material";
import FooterHome from "../components/FooterHome";

interface LocationType {
  id: number;
  name: string;
  address: string;
  openTime: string;
  closeTime: string;
  rating: number;
  isListed: boolean;
  requestedCount: number;
  activityList: ActivityType[];
  mapLink: string;
  contact: string;
}

interface ActivityType {
  price: number;
  typeName: string;
  id: number;
  name: string;
}

const Listings: FunctionComponent = () => {
  const [searchParams] = useSearchParams();
  const [listed, setListed] = useState<LocationType[]>([])
  const [unlisted, setUnlisted] = useState<LocationType[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [searchVal, setSearchVal] = useState(searchParams.get('searchVal'));

  const getLocationBySearch = async () => {
    try {
      setIsLoading(true);
      const response = await AuthRequest.get<{ data: LocationType[] }>(`Search/customer/locations?searchText=${searchVal}`);
      console.log(response.data);

      // Filter the data into listed and unlisted locations
      const listedLocations = response.data.data.filter((location: LocationType) => location.isListed);
      const unlistedLocations = response.data.data.filter((location: LocationType) => !location.isListed);

      // Update the state with the filtered arrays
      setListed(listedLocations);
      setUnlisted(unlistedLocations)
    } catch (error) {
      console.log('error', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    localStorage.setItem('searchVal', searchVal ? searchVal : '');
    getLocationBySearch();
  }, [searchVal])

  return (
    <div className="w-full relative bg-dark-blue overflow-hidden flex flex-col items-end justify-start pt-[3.25rem]">
      <section className="self-stretch flex flex-col items-center justify-center">
        <Navbar />
        <div className="w-[71.063rem] lg:w-full max-w-full flex flex-col px-[1.25rem]">
          <div className="w-7/12 lg:w-full">
            <div className="relative">
              <input
                placeholder="Search by name or location"
                value={searchVal ? searchVal : ''}
                onChange={(e) => { setSearchVal(e.target.value) }}
              />
              <img
                width="24px"
                height="24px"
                src="/iconamoonsearchbold.svg"
                className="cursor-pointer absolute top-[18px] right-6"
              />
            </div>
            {/* <TextField
              className="[border:none] bg-[transparent] h-[3rem] font-inter font-semibold text-[0.875rem] text-pure-white max-w-full"
              placeholder={'Search Locations'}
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <img
                    width="24px"
                    height="24px"
                    src="/iconamoonsearchbold.svg"
                    className="cursor-pointer"
                  />
                ),
              }}
              sx={{
                "& fieldset": { borderColor: "#baffd4" },
                "& .MuiInputBase-root": {
                  height: "48px",
                  paddingRight: "16px",
                  borderRadius: "100px",
                  fontSize: "14px",
                },
                "& .MuiInputBase-input": { color: "#fff" },
                width: "100%",
              }}
            /> */}
          </div>
          <h2 className="m-0 my-10 title-gradient">
            Search Results: {searchVal ? searchVal : ''}
          </h2>
          {
            isLoading ? (
              <div className="flex justify-center items-center h-full">
                <CircularProgress />
              </div>
            ) : (listed.length > 0) || (unlisted.length > 0) ? (
              <IndividualListing listedLocations={listed} unlistedLocations={unlisted} />
              // listed.map((location: any) => (
              //   <IndividualListing
              //     key={location.id} // Assuming each location has a unique id, add a key prop for list items
              //     name={location.name}
              //     address={location.address}
              //     rating={location.rating}
              //     activities={location.activityList}
              //     link={`/book-now?locationId=${location.id}`}
              //     time="9am to 10pm"
              //   />
              // ))
            ) : (
              <h2 className="m-0 text-[1.875rem] my-16 self-stretch relative leading-[100%] font-light font-inherit text-transparent !bg-clip-text [background:linear-gradient(89.74deg,_#baffd4,_#82f8ff_40.33%,_#ffd3d8)] [-webkit-background-clip:text] [-webkit-text-fill-color:transparent] mq450:text-[1.875rem] mq450:leading-[1.875rem] mq1025:text-[2.5rem] mq1025:leading-[2.5rem]">
                No Results Found!
              </h2>
            )
          }
        </div>
      </section>
      {/* <MainFrameContainer /> */}
      <FooterHome />
    </div>
  );
};

export default Listings;
