import { FunctionComponent } from "react";
import HeaderHome from "../components/HeaderHome";
import FooterHome from "../components/FooterHome";
import Navbar from "../components/Navbar";
import Button from "../components/Button";
import AuthStatus from '../components/AuthStatus';
import { useNavigate } from "react-router-dom";

const Home: FunctionComponent = () => {

  const navigate = useNavigate();

  return (
    <div className="bg-dark-blue pt-[3.25rem]">
      <Navbar />
      <HeaderHome />
      {/* <QuoteHome /> */}
      {/* <TestimonialsHome /> */}
      {/* <HiddenVenuHome /> */}
      {/* <section className="w-full flex flex-col items-center relative">
        <div className="lg:w-full w-[71rem] flex flex-row mx-auto relative h-full items-center">
          <h3 className="title-gradient w-7/12 lg:w-10/12 absolute left-0 top-40 lg:top-36 lg:pl-[1.25rem] my-0">Discover hidden gems around you</h3>
        </div>
        <img
          className="w-full object-cover h-[450px]"
          alt=""
          src="/frame-container.png"
        />
      </section> */}
      {/* <section className="w-full flex my-[128px] lg:px-[1.25rem]">
        <div className="w-[71rem] lg:w-full flex flex-col mx-auto items-start">
          <div className="w-7/12 lg:w-full">
            <p className="label2 uppercase title-gradient">Discover Your Play Style</p>
            <h2 className="title-gradient pb-4">Whether you play with friends, family or share a passion for the sport. </h2>
            <h2 className="title-gradient">We got you.</h2>
          </div>
          <div className="w-8/12 lg:w-full flex lg:flex-col items-center lg:items-start gap-8 mt-8">
            <div className="flex gap-4">
              <img src="/search.svg" alt="" />
              <p className="body text-white">Find sporting venues</p>
            </div>
            <img src="/ggchevrondowno-4@2x.png" className="h-8 w-auto lg:rotate-90" alt="" />
            <div className="flex gap-4">
              <img src="/calendar.svg" alt="" />
              <p className="body text-white">Book online</p>
            </div>
            <img src="/ggchevrondowno-4@2x.png" className="h-8 w-auto lg:rotate-90" alt="" />
            <div className="flex gap-4">
              <img src="/shuttle.svg" alt="" />
              <p className="body text-white">Play your game</p>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section className="w-full flex py-[69px] bg-glass lg:px-[1.25rem]">
        <div className="w-[71rem] lg:w-full flex flex-row lg:flex-col mx-auto items-start">
          <div className="w-6/12 lg:w-full">
            <p className="label2 title-gradient uppercase m-0">Coming Soon</p>
            <h3 className="title-gradient py-8 m-0">Join Sri Lanka’s leading sports marketplace</h3>
            <p className="body text-white pb-12 m-0">Whether you're a seasoned pro or just starting out, this one-stop platform will make it easy for you and other players to discover venues and connect with  sports enthusiasts. </p>
            <AuthStatus>
              {(isLoggedIn, firstName) => isLoggedIn ? (
                ''
              ) : (
                <Button btnText="Join the Waitlist" click={() => navigate('/signup')} />
              )}
            </AuthStatus>
          </div>
          <div className="w-6/12 lg:w-full flex justify-center lg:mt-8">
            <img src="/coming-soon.png" className="w-full" alt="" />
          </div>
        </div>
      </section> */}
      <FooterHome />
    </div>
  );
};

export default Home;
