import { useState, useEffect } from "react";

interface SelectTimingProps {
    startTime: string;
    endTime: string;
    onTimingChange: (startTime: string, endTime: string, isChecked: boolean) => void;
    initialChecked?: boolean;  // New prop to indicate if this timing should be checked initially
}

const SelectTiming = ({ startTime, endTime, onTimingChange, initialChecked = false }: SelectTimingProps) => {
    const [isChecked, setIsChecked] = useState(initialChecked);

    useEffect(() => {
        setIsChecked(initialChecked);  // Set the initial checked state based on prop
    }, [initialChecked]);  // Re-run if `initialChecked` changes

    const handleCheck = () => {
        const newCheckedState = !isChecked;
        setIsChecked(newCheckedState);
        onTimingChange(startTime, endTime, newCheckedState);
    };

    return (
        <label
            className={`min-w-[220px] 
                flex 
                items-center 
                rounded-2xl
                p-4
                mb-4
                border-solid
                border-[#CBF0D9]
                ease-in-out
                justify-center
                h-12
                cursor-pointer
                border 
                transition-colors 
                duration-200
                ${isChecked
                    ? "bg-white text-gray-800"
                    : "bg-transparent text-white border-white"
                }`}
        >
            <input
                type="checkbox"
                className="hidden"
                checked={isChecked}
                onChange={handleCheck}
            />
            <span className="text-sm font-medium">{startTime} - {endTime}</span>
        </label>
    );
};

export default SelectTiming;
