interface ButtonProps {
    isLoading?: boolean,
    click?: () => void,
    btnText: string
    disabled?: boolean
    classes?: string
}

export default function Button({ isLoading = false, btnText, click, disabled = false, classes }: ButtonProps): JSX.Element {
    return (
        <button
            disabled={isLoading || disabled}
            onClick={click}
            className={`mt-4 btnPrimary ${isLoading ? 'cursor-not-allowed' : 'cursor-pointer'} ${classes ? classes : ''}`}
        >
            <div className="flex-1 body text-navy-blue">
                {isLoading ? "Loading..." : btnText}
            </div>
        </button>
    );
}
